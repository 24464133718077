import React from 'react';
import '../../css/blogpost.css';
import ChoiceBets from '../../assets/BlogPost-4ChoiceBets.png';
import ClaimInsurance from '../../assets/BlogPost-ClaimInsurance.png';
import GamingPlan from '../../assets/BlogPost-GamingPlan.png';
import UnlimitedGamePlay from '../../assets/BlogPost-UnlimitedGamePlay.png';
import UnlimitedPlays from '../../assets/BlogPost-UnlimitedPlays.png';
import SiteBanner from '../../assets/site-banner.png';

import { GoPrimitiveDot } from 'react-icons/go';

function BlogPostCasinoGame() {
    return (
        <>
        <div className='blogpost-container'>
            <div className='blogposthero-col'>
                <div className='blogposthero-row-header'>
                    <p className='blogpost-header-text'>
                        <b>HAVE YOU HEARD OF A SURE-WIN CASINO GAME?</b>
                    </p>
                </div>
                
                <div className='blogposthero-row'>
                    <p className='blogposthero-content'>
                        It's a new blockchain-based game you can play. But this time with insurance, this means if you lose a bet you will have insurance to cover your loss. 
                    </p>
                    <p className='blogposthero-content'>
                        Now you'll be thinking, is this a SCAM? How does the casino make money if they keep letting players win? Let us break it down for you, there's a two-style playing mode. 
                    </p>
                    <p className='blogposthero-content'>
                        First, you need to follow a formula in order for you to win. The formula depends on which game plan you subscribe to. Here's the catch, you can only win ONCE per day! 
                    </p>
                    <p className='blogposthero-content'>
                        HUH? If you only win ONCE per day? Isn't that just not casino-like? 
                    </p>
                    <p className='blogposthero-content'>
                        Now, after you have won for the day. You can choose to proceed if you want to test your luck.
                    </p>

                    <img src={UnlimitedPlays} className='homeBanner' />
                </div>

                <div className='blogposthero-row-header'>
                    <p className='blogpost-content-text'>
                        Figure 1: Unlimited Plays
                    </p>
                </div>
                <div className='blogposthero-row'>
                    <p className='blogposthero-content'>
                        The second part of the game is to have unlimited time to play the game. You can bet any amount and play unlimited times. The best part of this is that the odds of winning are 25% because you can only choose 4 modes to bet.
                    </p>

                    <img src={ChoiceBets} className='homeBanner' />
                </div>
                <div className='blogposthero-row-header'>
                    <p className='blogpost-content-text'>
                        Figure 2: 4 Choice Bet
                    </p>
                </div>
                    
                <div className='blogposthero-row'>
                    <p className='blogposthero-content'>
                        Have you played any casino game that has a 25% winning rate? I'm sure I haven't met any casino that offers such a high winning rate. If you do please email me, I'd like to invest in it myself.
                    </p>
                    <p className='blogposthero-content'>
                        Are you still with me? If you're still interested, keep reading.
                    </p>
                    <p className='blogposthero-content'>
                        Introducing AW3G, A Web 3 Gaming. The new era of blockchain Play-to-Earn gaming. 
                    </p>

                    <img src={SiteBanner} className='homeBanner' />
                </div>
                
                <div className='blogposthero-row-header'>
                    <p className='blogpost-content-text'>
                    Figure 3: AW3G
                    </p>
                </div>

                <div className='blogposthero-row'>
                    <p className='blogposthero-content'>
                        How does the game actually work? It's simple, you just have to guess the end two digits results of a blockchain transaction. This game uses the TRON blockchain network as its gaming core. The game logic looks like this, the below is an example of a few TRON blockchain transactions.
                    </p>

                    <p className='blogposthero-content'>
                        Winning example
                        <br/>
                        D30737E3652A830C288A829BD71889C86DA9AB29CD13BC6ABC2E1<span className='red-input-underline'>3F</span>
                        <br/>
                        CB50AF649CED042219D949712A24A8F2B894B74D79B01012056D0<span className='red-input-underline'>A2</span>
                    </p>
                    <p className='blogposthero-content'>
                        Losing Example
                        <br/>
                        A3127739CABC0E492BD42C3DC25955BE03810291A0E90661E6E7E5<span className='red-input-underline'>78</span>
                        <br/>
                        7212C4A5ABCA19D9479CEF6B06BCD799BD3D17FA5D8B54223A4BE7<span className='red-input-underline'>FD</span>
                    </p>
                    <p className='blogposthero-content'>
                        Let's start with the winning example. The winning criteria are to have one number and one alphabet as the end two digits result from the blockchain transaction. 
                    </p>
                    <p className='blogposthero-content'>
                        You lose if the blockchain transaction hash ends with either both numbers or both alphabets. 
                    </p>
                    <p className='blogposthero-content'>
                        Pretty simple huh? The gameplay is THAT easy to understand!
                    </p>
                    <p className='blogposthero-content'>
                        What is the highlight of this game? 
                    </p>
                    <p className='blogposthero-content'>
                        <GoPrimitiveDot />Transparent gaming - Every blockchain transaction is visible on-chain. It cannot be manipulated
                        <br/>
                        <GoPrimitiveDot />Your funds are secure - They don't need your personal details. Withdraw profits at anytime
                        <br/>
                        <GoPrimitiveDot />Fastest gaming reward - Spend a minimum of 60 seconds of your time to get rewarded
                    </p>
                    <p className='blogposthero-content'>
                        I think I left out the most important thing about the casino game. This is a sure-win casino game if you follow the formula. Here's the detail of the game plan and formula
                    </p>
                    <p className='blogposthero-content'>
                        <img src={GamingPlan} className='homeBanner' />
                    </p>
                    </div>

                    <div className='blogposthero-row-header'>
                        <p className='blogpost-content-text'>
                            Figure 4: Gaming Plan
                        </p>
                    </div>
                <div className='blogposthero-row'>
                    <p className='blogposthero-content'>
                        Just choose the game plan you want to play. The minimum capital to play is $126. Simply follow their formula to win. What if you lose? How to claim it back? 
                    </p>
                    <p className='blogposthero-content'>
                        For example, you choose a game plan of $630. You follow through the formula and played 10,20,40,80,160,320 (in total 6 times) and you lose all of them! What happens next? The following screen will appear:-
                    </p>
                    </div>
                    <p className='blogposthero-content'>
                        <img src={ClaimInsurance} className='homeBanner' />
                    </p>
                <div className='blogposthero-row-header'>
                        <p className='blogpost-content-text'>
                        Figure 5: Claim Insurance
                        </p>
                    </div>
                    <div className='blogposthero-row'>
                    <p className='blogposthero-content'>
                        Claim back what you lose, then follow the formula and play again until you win for the day.
                    </p>
                    <p className='blogposthero-content'>
                        Thanks to the platform partner All Risk Insurance providing liquidity to the casino. They have you all covered for the loss.
                    </p>
                    <p className='blogposthero-content'>
                        Feeling lucky after you have won? Yes of course you can jump straight right into gamble mode!
                    </p>
                    <p className='blogposthero-content'>
                        <img src={UnlimitedGamePlay} className='homeBanner' />
                    </p>
                    </div>

                    <div className='blogposthero-row-header'>
                        <p className='blogpost-content-text'>
                        Figure 6: Unlimited Game Play
                        </p>
                    </div>
                    <div className='blogposthero-row'>
                    <p className='blogposthero-content'>
                        The betting play is as shown in figure 6 above. Pretty much easy to win in my opinion, it's a 25% chance to win! You just have to guess the correct pattern to win.
                    </p>
                    <p className='blogposthero-content'>
                        Their customer service is very fast in responding too. You can contact them via Telegram at <a className='blogpost-links' href='https://t.me/aw3gcs'>https://t.me/aw3gcs</a>, expect them to reply in 30 - 60 minutes.
                    </p>
                    <p className='blogposthero-content'>
                        By the way, if you want to have another passive income. I strongly recommend you to join and play this game. All you have to do is to spend a minimum of 60 seconds of your time playing it.
                    </p>
                    <p className='blogposthero-content'>
                        Interested? This is an invitation only game, only people who have a referral link can register an account to play the game.
                    </p>
                    <p className='blogposthero-content'>
                    Join the game now! Here's the link
                    <br/>
                    <a className='blogpost-links' href='https://bit.ly/3vpH4jX'>https://bit.ly/3vpH4jX</a>
                    <br/>
                    <a className='blogpost-links' href='https://bit.ly/3vpH4jX'>https://bit.ly/3vpH4jX</a>
                    <br/>
                    <a className='blogpost-links' href='https://bit.ly/3vpH4jX'>https://bit.ly/3vpH4jX</a>
                    <br/>
                    Let's get it !!! 
                    </p>
                </div>
            </div>
        </div>
        </>
      );
}

export default BlogPostCasinoGame;