import React, { useEffect } from 'react';
import '../../css/profile.css';
import '@aws-amplify/ui-react/styles.css';
import ProfileHero from '../layouts/profilehero';
import Navbar from '../layouts/navbar';

import { CgProfile } from 'react-icons/cg';
import { Authenticator } from '@aws-amplify/ui-react';
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';

function Profile() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === 'authenticated' ? 'FIRST PROTECTED ROUTE!' : 'Loading...';
    
  return (
    <>
    <div className='profile-container'>
        <div className='navbar-container'>
            <Navbar />
        </div>
    </div>

    <Authenticator>
    <div className='profile-container'>
        <div className='navbar-container-inner'>
        <CgProfile className='profile-icon' size={26} /> PROFILE
        </div>
    </div>

    
    <div className='profilehero-container'>
        <ProfileHero />
    </div>
    </Authenticator>
    </>
  );
}

export default Profile;