import React, { useState, useEffect } from 'react';
import '../../css/assets.css';
import Deposit from './deposit';
import Withdraw from './withdraw';
import { Authenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { Auth } from 'aws-amplify';


function AssetsHero() {
  const [ depositPopup, setDepositPopUp ] = useState(false);
  const [ withdrawPopup, setWithdrawPopUp ] = useState(false);
  const [ currentUser, setCurrentUser ] = useState('');
  const [ withdrawList, setWithdrawList ] = useState([]);
  const [ depositList, setDepositList ] = useState([]);

  const [ userGamePoint, setUserGamePoint ] = useState('');
  const [ userCommissionEarned, setUserCommissionEarned ] = useState('');

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      //setCurrentUser(user.attributes.email.split("@", 1));
      setCurrentUser(user.attributes.email);
      setUserGamePoint(user.attributes.zoneinfo);
      setUserCommissionEarned(user.attributes.name);
      
      fetchWithdraws(user.attributes.email).then((x) => {
        let tempWithrawList = [];
        let fetchThisNumber = 0;

        if (x.length <= 5) {
          fetchThisNumber = x.length;
        }
        else {
          fetchThisNumber = 5;
        }
        for (var i = 0 ; i < fetchThisNumber ; i ++) {
          tempWithrawList.push(x[i]);
        }

        setWithdrawList(tempWithrawList);
      });

      fetchDeposits(user.attributes.email).then((y) => {
        let tempDepositList = [];
        let fetchThisNumber = 0;

        if (y.length <= 5) {
          fetchThisNumber = y.length;
        }
        else {
          fetchThisNumber = 5;
        }
        for (var i = 0 ; i < fetchThisNumber ; i ++) {
          tempDepositList.push(y[i]);
        }

        setDepositList(tempDepositList);
      });
      
    })
    .catch(err => {});
  }, []);

  async function fetchWithdraws(fetchThisUser) {
    //console.log('inside fetchWithdraws function, currentUser is now: ' + fetchThisUser);
    //const withdrawRaw = await API.graphql({ query: queries.listWithdraws, variables: { filter: {requestedBy: { eq: fetchThisUser} }}});
    //const withdrawArray = withdrawRaw.data.listWithdraws.items;

    const withdrawRaw = await API.graphql({
      query: queries.withdrawsByDate,
      variables: {
        sortDirection: 'DESC',
        requestedBy: fetchThisUser,
        type: 'withdraw'
      },
      authMode:'AMAZON_COGNITO_USER_POOLS'
    });
    const withdrawArray = withdrawRaw.data.withdrawsByDate.items;
    //const oneTodo = await API.graphql(graphqlOperation(queries.getTodo, { id: 'U77OH0FN' }));
    
    return withdrawArray
  }

  async function fetchDeposits(fetchThisUser) {
    //console.log('inside fetchDeposits function, currentUser is now: ' + fetchThisUser);

    const depositRaw = await API.graphql({
      query: queries.depositsByDate,
      variables: {
        sortDirection: 'DESC',
        requestedBy: fetchThisUser,
        type: 'deposit'
      },
      authMode:'AMAZON_COGNITO_USER_POOLS'
    });
    const depositArray = depositRaw.data.depositsByDate.items;

    
    //const oneTodo = await API.graphql(graphqlOperation(queries.getTodo, { id: 'U77OH0FN' }));
    
    return depositArray
  }

  return (
    <>
    <Authenticator>
        {({ user }) => (
      <div className='assets-container'>
       <div className='assetshero-col'>
         <div className='assetshero-row'>
            <div className='assets-floatleft'>
              <p className='assets-gamepoint-input-text'><b>Game Point</b></p>
            </div>
            
            
            <div className='assets-floatright'>
              <a href='/deposit'>
              <button className='assetshero-deposit-button'>
                <span className='button-text'>Deposit</span>
              </button>
              </a>

              <a href='/withdraw'>
              <button className='assetshero-withdraw-button'>
                <span className='button-text'>Withdraw</span>
              </button>
              </a>
            </div>
            
          </div>
        
          <div className='assetshero-row'>
            <p className='assets-gamepoint-input'>$ {parseFloat(parseFloat(userGamePoint) + parseFloat(userCommissionEarned)).toFixed(2)}</p>
          </div>
        </div>
      </div>
      )}
    </Authenticator>

    <Authenticator>
        {({ user }) => (
      <div className='assets-container'>
        <div className='assetshero-col'>
          <div className='assetshero-row'>
            <p><b>Deposit History</b></p>
          </div>
        
          <div className='assetshero-row'>
            <table id='deposit-table'>
              <tbody>
                <tr>
                  <th>Reference</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
            {
              depositList.map((deposit, index) => (
                <tr key={index}>
                  <td>{deposit.id}</td>
                  <td>$ {deposit.depositAmount}</td>
                  <td>{deposit.status}</td>
                </tr>
              ))
            }
            </tbody>
            </table>
          </div>
        </div>
      </div>
      )}
    </Authenticator>

    <Authenticator>
          {({ user }) => (
      <div className='assets-container'>
        <div className='assetshero-col'>
          <div className='assetshero-row'>
            <p><b>Withdrawal History</b></p>
          </div>
        
          <div className='assetshero-row'>
          <table id='withdraw-table'>
              <tbody>
                <tr>
                  <th>Reference</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
            {
              withdrawList.map((withdraw, index) => (
                <tr key={index}>
                  <td>{withdraw.id}</td>
                  <td>$ {withdraw.withdrawAmount}</td>
                  <td>{withdraw.status}</td>
                </tr>
              ))
            }
            </tbody>
            </table>
          </div>
        </div>
      </div>
      )}
    </Authenticator>
        
    </>
  );
}

export default AssetsHero;