import React, { useState, useEffect } from 'react';
import '../../css/game.css';
import TronLogo from '../../assets/tron-logo.png';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import CoinRainBox from '../layouts/coinrain';

function GameTron() {
    // Used for time ticker
    const [ nextRoundTime, setNextRoundTime ] = useState();
    const [ roundEndTime, setRoundEndTime ] = useState('');

    // Used for block and hash updates in various parts of the page
    const [ latestBlock, setLatestBlock ] = useState('');
    const [ latestHash, setLatestHash ] = useState('');
    const [ bettingBlock, setBettingBlock ] = useState('');

    // Used for setting block sync state
    const [ syncBlock, setSyncBlock ] = useState(true);

    // Used for user input, client side controls
    const [ betInputValue, setBetInputValue ] = useState('');
    const [ betInputDisabled, setBetInputDisabled ] = useState(false);

    // Used for checks on whether users won
    const [ userFinalInput, setUserFinalInput ] = useState('');
    //let userFinalInput = '';

    // Static messages
    const [ betMessage, setBetMessage ] = useState('');
    const [ allowBetMessage, setAllowBetMessage ] = useState('');
    const [ allowBetMessageContinue, setAllowBetMessageContinue ] = useState('');
    const betOpenMessage = 'Playing for block';
    const betCloseMessage = 'Closed for block';
    const syncMessage = 'Syncing blocks, please wait for next round to start...';
    // Moving the below to SubmitBet function, otherwise, it won't update
    //const betSubmitMessage = 'Your submission for this block is ' + userFinalInput;

    const [ betHistory, setBetHistory ] = useState([]);
    const [ currentUser, setCurrentUser ] = useState('');
    const [ userGamePoints, setUserGamePoints ] = useState('');
    const [ userCommEarned, setUserCommEarned ] = useState('');
    const [ userPlayStatus, setUserPlayStatus  ] = useState('');
    const [ userGamePlan, setUserGamePlan] = useState('');

    const [ gameExpectedValue, setGameExpectedValue] = useState('');
    const [ showClaimButton, setShowClaimButton ] = useState(false);
    const [ showContinuePlayButton, setShowContinuePlayButton ] = useState(false);
    const [ insuranceClaimMessage, setInsuranceClaimMessage ] = useState('');

    const [ lightboxDisplay, setLightBoxDisplay ] = useState(false);
    const [ loadingGif, setLoadingGif ] = useState('');

    const regex4AlphaNumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/g;
    const regex4Digits = /[0123456789]/g;
    const regex4MultipleDots = /.*\..*\..*/g;
    
    useEffect(() => {
        const id = setTimeout(() => {
            setNextRoundTime(60 - new Date().getSeconds());
            const roundEnd = 30 - new Date().getSeconds();
            // If the round hasn't ended, e.g. roundEnd still has seconds left in it
            // Also, if we are not syncing blocks
            // continue the countdown
            if (roundEnd > 0 && !syncBlock) {
                setRoundEndTime(roundEnd);
                setBetMessage(betOpenMessage);
                //setBetInputDisabled(false);
            }
            // If the round has ended, e.g. roundEnd seconds is anything but positive numbers
            // Also, if we are not syncing blocks
            // stop the countdown
            else if (roundEnd < 1 && !syncBlock) {
                setRoundEndTime(0);
                setBetMessage(betCloseMessage);
                setBetInputDisabled(true);
            }
            
            // when the nextRoundTime countdown reaches 0, e.g. the round has ended
            // Also, if we are not syncing blocks
            // Also, user has not won today
            // initiate a new round
            //console.log('nextRoundTime is now ' + nextRoundTime);
            // setting nextRoundTime <= 1 because sometimes the timer skips a second or 2 if the window is not in focus
            if (nextRoundTime != null && nextRoundTime <= 1 && !syncBlock && userPlayStatus != 'disallow') {
                //console.log('Next round countdown reached 0, calling startNewRound()...');
                setBetInputDisabled(false);
                startNewRound();
            }
            // DEBUG INFO
            //console.log('Next round time: ' + nextRoundTime);
        }, 1000);
        
        // For first page load only
        // will return the most recent block, with existing hash
        if (latestBlock === '') {
            // This will determine whether we should sync blocks
            // If users join with under 55 seconds left in the round,
            // The bettting block they get will be too far in the future
            // Hence when the time comes to fetch the result hash, the hash will not be available for the betting block

            // If users join between 56 - 60 seconds left of the current round,
            // set the sync block to false to allow next steps to process
            if (nextRoundTime > 55 && userPlayStatus != 'disallow') {
                setSyncBlock(false);
                //console.log('setting this!! ' + userPlayStatus);
                setBetInputDisabled(false);
                onLoadRound();
            }

            // Otherwise, set the syncing message
            else {
                // Disables the input box when syncing blocks
                setBetInputDisabled(true);
                setBetMessage(syncMessage);
            }
            
        }

        return () => clearTimeout(id);
    
    });

    useEffect(() => {
        refreshUserStats();
    }, []);

    async function UserWonToday() {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          });
        //console.log("user.attributes.address is now: " + user.attributes.address);
        if (user.attributes.address === 'allow') {
            return false
        }
        else {
            return true
        }
    }
    
    // Get the latest block number, which already has a hash
    // DO NOT USE WHAT IS RETURNED AS A NEXT BLOCK NUMBER
    // PLUS 20 TO IT FIRST BEFORE ALLOWING USERS TO BET ON IT
    async function fetchTronBlock() {
        try {
        const responseRaw = await fetch("https://apilist.tronscan.org/api/system/status");
        const response = await responseRaw.json();
        return(response.database.block);
        } catch (e) {
            console.log('an error occurred...');
            setLatestBlock('N/A');
            setLatestHash('ERROR!');
        }
    }
    
    // Fetch the block details, specifically, the hash value
    async function fetchTronBlockDetail(fetchThisBlock) {
        // DEBUG INFO
        //fetchThisBlock = '41798632';
        //console.log('inside fetchTronBlockDetail, fetching this block: ' + fetchThisBlock);
        const responseRaw = await fetch("https://apilist.tronscan.org/api/block?number=" + fetchThisBlock);
        const response = await responseRaw.json();

        // If the tron block doesn't have a hash yet, meaning the transaction volume has gone down
        if (response.total === 0) {
            window.alert('Error fetching block hash because hash has not been allocated by TRON yet. Refreshing page and refunding your previous bet amount');
            
            //window.location.reload();
            window.location.href = "/game/tron";
            return('N/A');
        }
        else {
            return(response.data[0].hash);
        }
    }

    function startNewRound() {
        // DEBUG INFO
        //console.log('userFinalInput is: ' + userFinalInput);
        //console.log('fetching winning results of: ' + bettingBlock);

        // Starting a new round, but we need to get the results of the betting block first
        //console.log('Before fetchTronBlockDetail');
        fetchTronBlockDetail(bettingBlock).then((x) => {
            // DEBUG INFO
            //console.log('After fetchTronBlockDetail, it gave me this hash: ' + x);
            
            // This is to stop the code going into RoundEnd() function if there is no hash for this block
            if (x === 'N/A') {
                // DEBUG INFO
                console.log('Starting new round...');
                //window.location.href = "/game/tron";
                return;
            }

            // Fetch and set the hash of the betting block
            setLatestHash(x);
            // Calling RoundEnd() here, to make sure we have the lastestHash value to do the result checking
            RoundEnd(x.substring(62)).then(() => {
                //fetchBetHistory(currentUser).then((y) => {
                //    setBetHistory(y);
                //  });
            });
 
            // Set the lastest block to have the betting block
            setLatestBlock(bettingBlock);

            // TODO - use userFinalInput to somehow validate whether the they have won, and do the necessary processing
            // check Hashie to see how they do it

            // Before starting new round, we should clear all the below values
            // so it looks nice on the client side
            setUserFinalInput('');
            setBetInputValue('');

            // Then fetch a new block + 17 to use as the new betting block
            fetchTronBlock().then((y) => {
                setBettingBlock(y + 17);
            })
          })
    }

    function onLoadRound() {
        // Call fetchBlock, assign latest block number as x
        fetchTronBlock().then((x) => {
            // x may or may not have a hash, which is why we are using x - 1
            setLatestBlock(x - 1);

            // Use current block + 17 for future bets
            // This will be used in the startNewRound function
            setBettingBlock(x + 17);

            // Pass x - 1 to fetchBlockDetail and assign the hash value to y
            fetchTronBlockDetail(x - 1).then((y) => {
              setLatestHash(y);
            })
          })
    }

    function processBetInputChangeValue(userInput) {
        setBetInputValue(userInput.target.value);
    }

    // When a user submits their bet
    function SubmitBet() {
        // DEBUG INFO
        //console.log('betInputValue is now: ' + betInputValue);
        // Some checks on user input
        if (parseFloat(betInputValue) > (parseFloat(userGamePoints) + parseFloat(userCommEarned))) {
            setAllowBetMessage('Insufficient Game Points');
            return;
        }

        else if (betInputValue != '' && Math.sign(betInputValue) == 1 && regex4Digits.test(betInputValue) && !regex4MultipleDots.test(betInputValue)) {
            if (betInputValue.includes('.') && betInputValue != '0.5') {
                setAllowBetMessage('Only 0.5 is allowed');
                return;
            }
            setBetInputDisabled(true);
        
            // Since setUserFinalInput uses React's useState and its an async function
            // We need to wrap it around the below state block and do further processing
            // Otherwise it won't pick up the change, since async function needs time to finish
            // P.S. the setUserFinalInput line prior to the block is needed, don't know why...
            setUserFinalInput(betInputValue);
            setUserFinalInput((state) => {
                setUserFinalInput(state);
            });
        
            setUserGamePoints(parseFloat(userGamePoints) - parseFloat(betInputValue));
            setAllowBetMessage('');
        }

        else {
            setAllowBetMessage('Invalid input, try again');
        }
        
    }
    
    // Used to do stuff when the round has ended
    async function RoundEnd(checkThisHashValue) {
        // DEBUG INFO
        //console.log('inside RoundEnd function, userFinalInput is: ' + userFinalInput);
        //console.log('first character is: ' + checkThisHashValue.substring(0,1));
        //console.log('second character is: ' + checkThisHashValue.substring(1));
        //console.log('userFinalInput is: ' + userFinalInput);

        // Enabling the below will make sure we always LOSE
        //checkThisHashValue = '00';

        // Enabling the below will make sure we always WIN
        //checkThisHashValue = 'a0';

        let userBet = {};

        // User has placed a bet, so doing the checks
        if (userFinalInput != '') {
            //console.log('running checks on: ' + checkThisHashValue);
            // if the below returns true, the user has won
            if (regex4AlphaNumeric.test(checkThisHashValue)) {
                // DEBUG INFO
                //console.log('this is alpha numeric, setting user to win');

                // Building a win query
                userBet = {
                    user: currentUser,
                    betBlock: bettingBlock,
                    betAmount: userFinalInput,
                    betResult: 'win'
                };
                
                // If they won, add the values back for them
                // Calculate what we need to update the user's game points
                // Need to x2 userFinalInput because we have already taken it out from userGamePoints
                //const tempGamePointUpdate = parseFloat(userGamePoints) + parseFloat(userCommEarned) + (parseFloat(userFinalInput) * 2);
                const tempGamePointUpdate = parseFloat(userGamePoints) + (parseFloat(userFinalInput) * 2);
                updateUserGamePoints(tempGamePointUpdate, 'WinBetMode').then(() => {
                    refreshUserStats();
                });

                // Update the user's address attribute to disallow, so they won't be able to play anymore today
                updateUserPlayStatus().then(() => {
                    // Refresh the page so the page catches the new user attribute
                    refreshUserStats();
                });
                
                // Reset user's next expected game value ONLY if they have won
                // This is so the value is correct when they come and play the next day
                if (userGamePlan === '126') {
                    updateUserGameExpectedValue('2');
                }
                if (userGamePlan === '315') {
                    updateUserGameExpectedValue('5');
                }
                if (userGamePlan === '630') {
                    updateUserGameExpectedValue('10');
                }
                if (userGamePlan === '1260') {
                    updateUserGameExpectedValue('20');
                }
                if (userGamePlan === '1890') {
                    updateUserGameExpectedValue('30');
                }
                if (userGamePlan === '3150') {
                    updateUserGameExpectedValue('50');
                }
                if (userGamePlan === '6300') {
                    updateUserGameExpectedValue('100');
                }

                setLoadingGif('win');
                setLightBoxDisplay(true);
            }
            else {
                // DEBUG INFO
                //console.log('this is NOT alpha numeric, setting user to lose');

                // Building a lose query
                userBet = {
                    user: currentUser,
                    betBlock: bettingBlock,
                    betAmount: userFinalInput,
                    betResult: 'lose'
                };

                // If they lost, just provide the deducted value, update it, and refresh the user data
                //console.log('if they lost update with this gamePoint: ' + userFinalInput);
                updateUserGamePoints(userFinalInput, 'LoseBetMode').then(() => {
                    //console.log('after updateUserGamePoints, firing off refreshUserStats...');
                    refreshUserStats();
                });

                setLoadingGif('lose');
                setLightBoxDisplay(true);

                /* Start of 126 block */
                if (userGamePlan === '126' && gameExpectedValue === '2' && userFinalInput === '2') {
                    updateUserGameExpectedValue('4');
                }
                else if (userGamePlan === '126' && gameExpectedValue === '4' && userFinalInput === '4') {
                    updateUserGameExpectedValue('8');
                }
                else if (userGamePlan === '126' && gameExpectedValue === '8' && userFinalInput === '8') {
                    updateUserGameExpectedValue('16');
                }
                else if (userGamePlan === '126' && gameExpectedValue === '16' && userFinalInput === '16') {
                    updateUserGameExpectedValue('32');
                }
                else if (userGamePlan === '126' && gameExpectedValue === '32' && userFinalInput === '32') {
                    updateUserGameExpectedValue('64');
                }
                else if (userGamePlan === '126' && gameExpectedValue === '64' && userFinalInput === '64') {
                    updateUserGameExpectedValue('CLAIMABLE_126');
                }
                /* End of 126 block */
                /* Start of 315 block */
                if (userGamePlan === '315' && gameExpectedValue === '5' && userFinalInput === '5') {
                    updateUserGameExpectedValue('10');
                }
                else if (userGamePlan === '315' && gameExpectedValue === '10' && userFinalInput === '10') {
                    updateUserGameExpectedValue('20');
                }
                else if (userGamePlan === '315' && gameExpectedValue === '20' && userFinalInput === '20') {
                    updateUserGameExpectedValue('40');
                }
                else if (userGamePlan === '315' && gameExpectedValue === '40' && userFinalInput === '40') {
                    updateUserGameExpectedValue('80');
                }
                else if (userGamePlan === '315' && gameExpectedValue === '80' && userFinalInput === '80') {
                    updateUserGameExpectedValue('160');
                }
                else if (userGamePlan === '315' && gameExpectedValue === '160' && userFinalInput === '160') {
                    updateUserGameExpectedValue('CLAIMABLE_315');
                }
                /* End of 315 block */
                /* End of 630 block */
                /* Start of 630 block */
                if (userGamePlan === '630' && gameExpectedValue === '10' && userFinalInput === '10') {
                    updateUserGameExpectedValue('20');
                }
                else if (userGamePlan === '630' && gameExpectedValue === '20' && userFinalInput === '20') {
                    updateUserGameExpectedValue('40');
                }
                else if (userGamePlan === '630' && gameExpectedValue === '40' && userFinalInput === '40') {
                    updateUserGameExpectedValue('80');
                }
                else if (userGamePlan === '630' && gameExpectedValue === '80' && userFinalInput === '80') {
                    updateUserGameExpectedValue('160');
                }
                else if (userGamePlan === '630' && gameExpectedValue === '160' && userFinalInput === '160') {
                    updateUserGameExpectedValue('320');
                }
                else if (userGamePlan === '630' && gameExpectedValue === '320' && userFinalInput === '320') {
                    updateUserGameExpectedValue('CLAIMABLE_630');
                }
                /* End of 630 block */
                /* Start of 1260 block */
                else if (userGamePlan === '1260' && gameExpectedValue === '20' && userFinalInput === '20') {
                    updateUserGameExpectedValue('40');
                }
                else if (userGamePlan === '1260' && gameExpectedValue === '40' && userFinalInput === '40') {
                    updateUserGameExpectedValue('80');
                }
                else if (userGamePlan === '1260' && gameExpectedValue === '80' && userFinalInput === '80') {
                    updateUserGameExpectedValue('160');
                }
                else if (userGamePlan === '1260' && gameExpectedValue === '160' && userFinalInput === '160') {
                    updateUserGameExpectedValue('320');
                }
                else if (userGamePlan === '1260' && gameExpectedValue === '320' && userFinalInput === '320') {
                    updateUserGameExpectedValue('640');
                }
                else if (userGamePlan === '1260' && gameExpectedValue === '640' && userFinalInput === '640') {
                    updateUserGameExpectedValue('CLAIMABLE_1260');
                }
                /* End of 1260 block */
                /* Start of 1890 block */
                else if (userGamePlan === '1890' && gameExpectedValue === '30' && userFinalInput === '30') {
                    updateUserGameExpectedValue('60');
                }
                else if (userGamePlan === '1890' && gameExpectedValue === '60' && userFinalInput === '60') {
                    updateUserGameExpectedValue('120');
                }
                else if (userGamePlan === '1890' && gameExpectedValue === '120' && userFinalInput === '120') {
                    updateUserGameExpectedValue('240');
                }
                else if (userGamePlan === '1890' && gameExpectedValue === '240' && userFinalInput === '240') {
                    updateUserGameExpectedValue('480');
                }
                else if (userGamePlan === '1890' && gameExpectedValue === '480' && userFinalInput === '480') {
                    updateUserGameExpectedValue('960');
                }
                else if (userGamePlan === '1890' && gameExpectedValue === '960' && userFinalInput === '960') {
                    updateUserGameExpectedValue('CLAIMABLE_1890');
                }
                /* End of 1890 block */
                /* Start of 3150 block */
                else if (userGamePlan === '3150' && gameExpectedValue === '50' && userFinalInput === '50') {
                    updateUserGameExpectedValue('100');
                }
                else if (userGamePlan === '3150' && gameExpectedValue === '100' && userFinalInput === '100') {
                    updateUserGameExpectedValue('200');
                }
                else if (userGamePlan === '3150' && gameExpectedValue === '200' && userFinalInput === '200') {
                    updateUserGameExpectedValue('400');
                }
                else if (userGamePlan === '3150' && gameExpectedValue === '400' && userFinalInput === '400') {
                    updateUserGameExpectedValue('800');
                }
                else if (userGamePlan === '3150' && gameExpectedValue === '800' && userFinalInput === '800') {
                    updateUserGameExpectedValue('1600');
                }
                else if (userGamePlan === '3150' && gameExpectedValue === '1600' && userFinalInput === '1600') {
                    updateUserGameExpectedValue('CLAIMABLE_3150');
                }
                /* End of 3150 block */
                /* Start of 6300 block */
                else if (userGamePlan === '6300' && gameExpectedValue === '100' && userFinalInput === '100') {
                    updateUserGameExpectedValue('200');
                }
                else if (userGamePlan === '6300' && gameExpectedValue === '200' && userFinalInput === '200') {
                    updateUserGameExpectedValue('400');
                }
                else if (userGamePlan === '6300' && gameExpectedValue === '400' && userFinalInput === '400') {
                    updateUserGameExpectedValue('800');
                }
                else if (userGamePlan === '6300' && gameExpectedValue === '800' && userFinalInput === '800') {
                    updateUserGameExpectedValue('1600');
                }
                else if (userGamePlan === '6300' && gameExpectedValue === '1600' && userFinalInput === '1600') {
                    updateUserGameExpectedValue('3200');
                }
                else if (userGamePlan === '6300' && gameExpectedValue === '3200' && userFinalInput === '3200') {
                    updateUserGameExpectedValue('CLAIMABLE_6300');
                }
                /* End of 6300 block */
                
            }
            // Update the user's total played amount
            updateUserTotalPlayed(userFinalInput);

            // Submit the query
            await API.graphql({
                query: mutations.createUserBets,
                variables: {input: userBet},
                authMode:'AMAZON_COGNITO_USER_POOLS'
            });
        }

        setUserFinalInput('');
        await delay(2700);
        setLightBoxDisplay(false);
        setLoadingGif('');
    }

    async function refreshUserStats() {
        Auth.currentAuthenticatedUser({
            // Temporarily setting this to false, this needs to be true during production
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          }).then(user => {
              // DEBUG INFO
              //console.log('refreshing user attributes...');
              //setCurrentUser(user.attributes.email.split("@", 1));
              setUserGamePoints(user.attributes.zoneinfo);
              setUserCommEarned(user.attributes.name);
              setUserPlayStatus(user.attributes.address);
              
              setUserGamePlan(user.attributes.gender);
              setGameExpectedValue(user.attributes.locale);

              // This will see if the user is allowed to issue a claim
              if (user.attributes.locale.includes('CLAIM')) {
                setInsuranceClaimMessage('Insurance claim enabled, do you want to claim?');
                setShowClaimButton(true);
              }
              
              setCurrentUser(user.attributes.email);
              setCurrentUser((state) => {
                  setCurrentUser(state);
              
                  fetchBetHistory(state).then((x) => {
                      setBetHistory(x);
                  });
              
                  UserWonToday().then((wonStatus) => {
                      // If they have won, meaning wonStatus is true
                      if (wonStatus) {
                          // DEBUG INFO
                          //console.log('user is NOT allowed to play today...');
                          setBetInputDisabled(true);
                          setAllowBetMessage('You have won today! Come back again after GMT 0000 hours!');
                          setAllowBetMessageContinue('Or continue playing without insurance unlimited times!');
                          setShowContinuePlayButton(true);
                      }
                      else {
                          // DEBUG INFO
                          //console.log('user is allowed to play today...');
                          setAllowBetMessage('');
                    }
                });
            })
        })
        .catch(err => {});
    }

    async function updateUserPlayStatus() {
        //DEBUG INFO
        //console.log('inside updateUserPlayStatus function');
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          'address': 'disallow'
        });
    }

    async function updateUserTotalPlayed(userPlayedThisAmount) {
        //DEBUG INFO
        //console.log('inside updateUserTotalPlayed function');
        const user = await Auth.currentAuthenticatedUser();
        const tempNewTotalPlayed = parseFloat(user.attributes.website) + parseFloat(userPlayedThisAmount);
        await Auth.updateUserAttributes(user, {
          'website': tempNewTotalPlayed.toString()
        });
    }

    async function updateUserGamePoints(updateWithThisValue, mode) {
        //DEBUG INFO
        //console.log('inside updateUserGamePoints function with: ' + updateWithThisValue);
        //console.log('updateWithThisValue is typeof: ' + typeof updateWithThisValue);
        const user = await Auth.currentAuthenticatedUser();
        //console.log('updateUserGamePoints is mode: ' + mode + ' with value: ' + updateWithThisValue);
        if (mode === 'InsuranceClaimMode' || mode === 'WinBetMode' || mode === 'TopUpMode') {
            //console.log('Inside InsuranceClaim, Winbet and TopUpMode mode');
            await Auth.updateUserAttributes(user, {
                'zoneinfo': updateWithThisValue.toString()
            });
            return;
        }

        if (mode === 'LoseBetMode') { 
            //console.log('Inside LoseBetMode...');
            // If they lost less than what their commission earned has
            // It means they have enough in their commission earned amount
            //console.log('right before comparison, updateWithThisValue is: ' + updateWithThisValue + ' userCommEarned is: ' + userCommEarned);
            if (parseFloat(updateWithThisValue) <= parseFloat(userCommEarned)) {
                // Simply deduct the value and update
                const tempSubtract = parseFloat(user.attributes.name) - parseFloat(updateWithThisValue);
                //console.log('they have enough comm, tempSubtract is now: ' + tempSubtract);
                await Auth.updateUserAttributes(user, {
                    'name': tempSubtract.toString()
                });
                //return;
            }
        
            // If they lost more than what their commission earned has
            // We need to zero out their commission earned
            // and then subtract the rest from their total game points
            if (parseFloat(updateWithThisValue) > parseFloat(user.attributes.name)) {
                // Zero out their commission earned field
                //console.log('they dont have enough commission, Going to zero out their commission earned...');
                await Auth.updateUserAttributes(user, {
                    'name': '0'
                });

                // Do the calculations for remaining amount
                const remainingSubtract = parseFloat(updateWithThisValue) - parseFloat(user.attributes.name);
                const gamePointFinalValue = parseFloat(user.attributes.zoneinfo) - parseFloat(remainingSubtract);
                //console.log('updating the remaining with: ' + gamePointFinalValue);

                // Then fire it off to update
                await Auth.updateUserAttributes(user, {
                    'zoneinfo': gamePointFinalValue.toString()
                });
            }
        }
    }

    async function updateUserGameExpectedValue(updateWithThisValue) {
        //DEBUG INFO
        //console.log('inside updateUserGameExpectedValue function with: ' + updateWithThisValue);
        //console.log('updateWithThisValue is typeof: ' + typeof updateWithThisValue);
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            'locale': updateWithThisValue.toString()
        });
    }

    async function updateUserWithdrawDayCountDown(updateWithThisValue) {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            'picture': updateWithThisValue
        });
    }

    async function fetchBetHistory(fetchThisUser) {
        // DEBUG INFO
        //console.log(fetchThisUser);
        
        //const betHistoryRaw = await API.graphql({ query: queries.listUserBets, variables: { filter: {user: { eq: fetchThisUser}, isFirstTopUp: { eq: 'false'} }}});
        //const betHistoryArray = betHistoryRaw.data.listUserBets.items;
        
        const betHistoryRaw = await API.graphql({
            query: queries.betsByDate,
            variables: {
              sortDirection: 'DESC',
              user: fetchThisUser,
              limit: 10
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        
        const betHistoryArray = betHistoryRaw.data.betsByDate.items;
        return betHistoryArray
    }

    async function submitInsuranceClaim() {
        // DEBUG INFO
        //console.log('user has triggered an insurance claim');

        if (userGamePlan === '126') {
            updateUserGameExpectedValue('2');
        }
        else if (userGamePlan === '315') {
            updateUserGameExpectedValue('5');
        }
        else if (userGamePlan === '630') {
            updateUserGameExpectedValue('10');
        }
        else if (userGamePlan === '1260') {
            updateUserGameExpectedValue('20');
        }
        else if (userGamePlan === '1890') {
            updateUserGameExpectedValue('30');
        }
        else if (userGamePlan === '3150') {
            updateUserGameExpectedValue('50');
        }
        else if (userGamePlan === '6300') {
            updateUserGameExpectedValue('100');
        }

        // .split will split the value into an Array
        const tempClaimArray = gameExpectedValue.split("_");
        // The second value is what we need
        const tempClaimThis = tempClaimArray[1];
        
        // Fire off the function to update game points
        updateUserGamePoints(parseFloat(userGamePoints) + parseFloat(tempClaimThis), 'InsuranceClaimMode');

        // Then reset their withdraw days to 99
        updateUserWithdrawDayCountDown('99');

        setInsuranceClaimMessage('');
        setShowClaimButton(false);

        // After all the claim steps are done, refresh the page so the claimed value is picked up
        // on the frontend via refreshUserStats()
        // Delay before refreshing, in a production build, relod gets called too quickly
        await delay(2000);
        //window.location.reload();
        refreshUserStats();
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

  return (
    <>
    <div className='game-container-back'>
        <div className='gamehero-container-back'>
            <a href='/home'>
                <button className='close-button'>
                    <AiOutlineArrowLeft />Back
                </button>
            </a>
        </div>
    </div>

    <div className='game-container'>
        <div className='gamehero-container'>
            <div className='gamehero-containercol'>
                <div className='gamehero-containerrow'>
                    <img src={TronLogo} className='gamelogo-small' />
                    <h4 className='gametype-title'>TRX - TRON</h4>
                    <div className='gamepage-gamepoint'>GP: {parseFloat(parseFloat(userGamePoints) + parseFloat(userCommEarned)).toFixed(2)}</div>
                </div>
                
                <span>Previous Block</span>
                <h3>{latestBlock}</h3>
                <span className='hashResults'>{latestHash.substring(0, 62)} <b className='hashLastTwo'>{latestHash.substring(62)}</b></span>
                
            </div>
        </div>
    </div>

    <div className='game-container'>
        <div className='gamehero-container'>
            <div className='gamehero-containerrow'>
                <div className='gamehero-floatleft'>
                    <span>Round ends in</span>
                    <br />
                    <span>{roundEndTime} seconds</span>
                </div>
                
                <div className='gamehero-floatright'>
                    <span>Next round in</span>
                    <br />
                    <span>{nextRoundTime} seconds</span>
                </div>
            </div>
        </div>
    </div>

    <div className='game-container'>
        <div className='gamehero-container'>
            <div className='gamehero-containercol'>
                <h3>{betMessage}</h3>
                <h4>{bettingBlock}</h4>
                
                <div className='gamehero-containerrow'>
                    <div className='gamehero-floatleft'>
                        <input
                        
                            type='text'
                            className='game-play-input'
                            id='betInputValue'
                            value={betInputValue}
                            disabled={betInputDisabled}
                            onChange={processBetInputChangeValue}
                            placeholder='Amount'
                        />
                    </div>
                    
                    <div className='gamehero-floatright'>
                        <button className='game-play-button'
                            disabled={betInputDisabled}
                            onClick={SubmitBet}>PLAY
                        </button>
                    </div>
                </div>
                <p className='wonBetMessage'>{allowBetMessage}</p>
                <p>{allowBetMessageContinue}</p>
                {showContinuePlayButton && <a href='/game/tron/play'><button className='insurance-claim-button'>PLAY NOW</button></a>}
                <p>{insuranceClaimMessage}</p>
                {showClaimButton && <button onClick={submitInsuranceClaim} className='insurance-claim-button'>CLAIM</button>}
            </div>
        </div>
    </div>

    <div className='game-container'>
        <div className='gamehero-container'>
            <h4 className='gamehero-container-your-history'>Your history</h4>
            <hr />
            <table id='bethistory-table'>
                <tbody>
                    <tr>
                        <th>Time</th>
                        <th>Block</th>
                        <th>Amount</th>
                        <th>Result</th>
                        </tr>
                        {
                            betHistory.map((bet, index) => (
                                <tr key={index}>
                                    <td>{bet.createdAt}</td>
                                    <td>{bet.betBlock}</td>
                                    <td>$ {bet.betAmount}</td>
                                    <td>{bet.betResult}</td>
                                </tr>
                            ))
                        }
                </tbody>
            </table>
        </div>
    </div>
    <CoinRainBox trigger={lightboxDisplay} setTrigger={setLightBoxDisplay} gifLoad={loadingGif} />
    </>
  );
}

export default GameTron;