import React, { useState, useEffect } from 'react';
import '../../css/profile.css';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { FiCopy } from 'react-icons/fi';
import * as queries from '../../graphql/queries';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import "bootstrap/dist/css/bootstrap.css";

function DownLine() {
    const [ downlineList, setDownlineList ] = useState([]);
    const [ totalCommEarning, setTotalCommEarning ] = useState(0);
    const [ totalCommBonus, setTotalCommBonus ] = useState(0);

    const [ searchDate, setSearchDate ] = useState('2021-01');
    const [ currentUser, setCurrentUser ] = useState('');

    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            setCurrentUser(user.attributes.email);
            //fetchDownLines(user.attributes.email).then((x) => {
            //    setDownlineList(x);
            //});
            //setTotalCommEarning(user.attributes.name);
        })
        .catch(err => {});
    }, []);

    async function fetchDownLines(fetchThisUser) {
        var tempCommBonus = 0;
        // DEBUG INFO
        //console.log('fetching downline with: ' + fetchThisUser);

        // Fetch users that has 'upline' set as the current user AND
        // isFirstTopUp as false - this is because they are only allowed to receive commission when the referred user topped up
        const downlineRaw = await API.graphql({
            query: queries.listClientUsers,
            variables: { 
                filter: { upline: { eq: fetchThisUser }, isFirstTopUp: { eq: 'false' } }
            },
            authMode:'AMAZON_COGNITO_USER_POOLS'
          });
        //const downlineRaw = await API.graphql({ query: queries.listClientUsers, variables: { filter: {upline: { eq: fetchThisUser} }}});
        //console.log(downlineRaw);
        
        const downlineArray = downlineRaw.data.listClientUsers.items;
        
        // Loop through the returned value and add them up, then set it to display
        let tempTotalCommEarning = 0;
        for ( var i = 0 ; i < downlineArray.length ; i++ ) {
            tempTotalCommEarning += (downlineArray[i].referredUserGamePlan * 0.2);
        }

        tempCommBonus = parseFloat(tempTotalCommEarning).toFixed(2)
        setTotalCommEarning(tempCommBonus);

        /* Start of block to calculate bonus commission based on total commission earned */
        if (tempCommBonus >= 1 && tempCommBonus <= 2000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.08).toFixed(2));
        }
        else if (tempCommBonus >= 2001 && tempCommBonus <= 6000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.07).toFixed(2));
        }
        else if (tempCommBonus >= 6001 && tempCommBonus <= 10000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.06).toFixed(2));
        }
        else if (tempCommBonus >= 10001 && tempCommBonus <= 20000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.05).toFixed(2));
        }
        else if (tempCommBonus >= 20001 && tempCommBonus <= 30000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.04).toFixed(2));
        }
        else if (tempCommBonus >= 30001 && tempCommBonus <= 50000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.03).toFixed(2));
        }
        else if (tempCommBonus >= 50001 && tempCommBonus <= 100000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.02).toFixed(2));
        }
        else if (tempCommBonus >= 100001) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.01).toFixed(2));
        }
        else {
            setTotalCommBonus('0.00');
        }
        /* End of block to calculate bonus commission based on total commission earned */
        
        return downlineArray
    }

    async function MonthSearch() {
        // Clear the array list before adding new stuff to it
        setDownlineList([]);
        var tempCommBonus = 0;

        // DEBUG INFO
        //console.log('search this date: ' + searchDate);

        const downlineRaw = await API.graphql({
            query: queries.listClientUsers,
            variables: { 
              filter: { upline: { eq: currentUser },
                        isFirstTopUp: { eq: 'false' },
                        createdAt: { beginsWith: searchDate }
                      }
            },
            authMode:'AMAZON_COGNITO_USER_POOLS'
        });
        //const downlineRaw = await API.graphql({ query: queries.listClientUsers, variables: { filter: {upline: { eq: fetchThisUser} }}});
        //console.log(downlineRaw);
          
        const downlineArray = downlineRaw.data.listClientUsers.items;

        // Loop through the returned value and add them up, then set it to display
        let tempTotalCommEarning = 0;
        for ( var i = 0 ; i < downlineArray.length ; i++ ) {
            tempTotalCommEarning += (downlineArray[i].referredUserGamePlan * 0.2);
        }
        tempCommBonus = parseFloat(tempTotalCommEarning).toFixed(2)
        setTotalCommEarning(tempCommBonus);

        /* Start of block to calculate bonus commission based on total commission earned */
        if (tempCommBonus >= 1 && tempCommBonus <= 2000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.08).toFixed(2));
        }
        else if (tempCommBonus >= 2001 && tempCommBonus <= 6000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.07).toFixed(2));
        }
        else if (tempCommBonus >= 6001 && tempCommBonus <= 10000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.06).toFixed(2));
        }
        else if (tempCommBonus >= 10001 && tempCommBonus <= 20000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.05).toFixed(2));
        }
        else if (tempCommBonus >= 20001 && tempCommBonus <= 30000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.04).toFixed(2));
        }
        else if (tempCommBonus >= 30001 && tempCommBonus <= 50000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.03).toFixed(2));
        }
        else if (tempCommBonus >= 50001 && tempCommBonus <= 100000) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.02).toFixed(2));
        }
        else if (tempCommBonus >= 100001) {
            setTotalCommBonus(parseFloat(tempCommBonus*0.01).toFixed(2));
        }
        else {
            setTotalCommBonus('0.00');
        }
        /* End of block to calculate bonus commission based on total commission earned */

        setDownlineList(downlineArray);
    }

    function copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    } 

    function HandleSearchChange(e) {
        setSearchDate(e.target.value);
    }

    const popover = (
        <Popover id="popover-basic">
          <Popover.Body>
            Referral link copied !
          </Popover.Body>
        </Popover>
      );
    
    return (
        <>
        
        <div className='game-container-inner'>
        <div className='gamehero-container-back'>
            <a href='/profile'>
                <button className='close-button'>
                    <AiOutlineArrowLeft />Back
                </button>
            </a>
        </div>
    </div>

    <div className='assets-container'>
        <div className='navbar-container-inner'>
        <span className='deposit-text'>MY DOWNLINE</span>
        </div>
    </div>
    <Authenticator>
        {({ user }) => (
    <div className='profile-container'>
            <div className='profile-container-col'>
                
            <div className='profilehero-row-downline'>
                    <p className='profilevalue-downline'>
                        <input
                            type="text"
                            value={'https://' + window.location.hostname + '/login?refkey=' + user.attributes.email.split("@", 1)}
                            className='downline-ref-input'
                            disabled
                        />
                        <OverlayTrigger trigger="click" rootClose={true} placement="top" overlay={popover}>
                        <button
                            onClick={() => {
                                copyToClipboard('https://' + window.location.hostname + '/login?refkey=' + user.attributes.email.split("@", 1));
                            }}
                            className='signout-button'
                        >
                            <span className='copytoclipboardbutton'><FiCopy /> Copy To Clipboard</span>
                        </button>
                        </OverlayTrigger>
                    </p>
                </div>
            </div>
        </div>
        )}
        </Authenticator>
        <div className='profile-container'>
            <div className='profile-container-col'>
                
                <div className='passwordchange-container'>
                    <h3 className='commissionearning-h3'>Total Commission Earning: $ {totalCommEarning}</h3>

                    <h3 className='commissionearning-h3'>Total Bonus: $ {totalCommBonus}</h3>
                    
                    <div className='assetshero-containerrow'>
                        <div className='assetshero-floatleft'>
                            <select className='deposite-stripe-input' onChange={HandleSearchChange}>
                                <option value='2021-01'>2021-01</option>
                                <option value='2021-02'>2021-02</option>
                                <option value='2021-03'>2021-03</option>
                                <option value='2021-04'>2021-04</option>
                                <option value='2021-05'>2021-05</option>
                                <option value='2021-06'>2021-06</option>
                                <option value='2021-07'>2021-07</option>
                                <option value='2021-08'>2021-08</option>
                                <option value='2021-09'>2021-09</option>
                                <option value='2021-10'>2021-10</option>
                                <option value='2021-11'>2021-11</option>
                                <option value='2021-12'>2021-12</option>
                                <option value='2022-01'>2022-01</option>
                                <option value='2022-02'>2022-02</option>
                                <option value='2022-03'>2022-03</option>
                                <option value='2022-04'>2022-04</option>
                                <option value='2022-05'>2022-05</option>
                                <option value='2022-06'>2022-06</option>
                                <option value='2022-07'>2022-07</option>
                                <option value='2022-08'>2022-08</option>
                                <option value='2022-09'>2022-09</option>
                                <option value='2022-10'>2022-10</option>
                                <option value='2022-11'>2022-11</option>
                                <option value='2022-12'>2022-12</option>
                            </select>
                        </div>
                        
                        <div className='assetshero-floatright'>
                            <button className='deposite-stripe-button' onClick={MonthSearch}>
                                Search
                            </button>
                        </div>
                    </div>

                    <table id='downline-table'>
                        <tbody>
                            <tr>
                                <th>Username</th>
                                <th>Date</th>
                                <th>Amount</th>
                            </tr>
                            {
                                downlineList.map((downline, index) => (
                                    <tr key={index}>
                                        <td>{downline.id.split("@", 1)}</td>
                                        <td>{downline.createdAt.split("T", 1)}</td>
                                        <td>{downline.referredUserGamePlan}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
            </div>
        </div>
        
    </div>
    
    </>
  )
}

export default DownLine;