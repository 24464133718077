import React, { useState, useEffect } from 'react';
import '../../css/assets.css';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';

import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { createDeposits } from '../../graphql/mutations';

import { ToastContainer, toast } from 'react-toastify';

import Payment from './payment';

function Deposit(props) {
  const [ topupAmount, setTopupAmount ] = useState('0');
  const [ displayTopupAmount, setDisplayTopupAmount ] = useState('0');
  const [ currentUser, setCurrentUser ] = useState('');
  const [ topupCreditCardAmount, setTopupCreditCardAmount ] = useState('126');

  const [ paymentPlan, setPaymentPlan ] = useState('price_1LUCDZHH59ucUHmFmN1GElIB');

  let successDepositMessage = '';
  const errorDepositMessage = 'Request failed, refresh this page and try again';

  const regex4Malicious = /[a-z]|[A-Z]|[|!@#$%^&*()_+\-\\\[\]=\|<>?`~±§£™\ ]/g;
  const regex4Empty = /^\s*$/g;

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      //setCurrentUser(user.attributes.email.split("@", 1));
      setCurrentUser(user.attributes.email);
    })
    .catch(err => {});
  }, []);

  function SubmitDeposit() {
    // DEBUG INFO
    //console.log('submit withdraw button clicked');
    //console.log('topupAmount is: ' + topupAmount)
    setDisplayTopupAmount(topupAmount);

    if (isMalicious(topupAmount) || isEmpty(topupAmount)) {
      return
    }

    createDeposit();
  }

  async function createDeposit() {
    try {
      // Create values for the query
      let uniqueID = makeid(8);

      successDepositMessage = 'Please send USDT to the wallet below and contact customer service';

      // Build the query
      const deposit = {
        id: uniqueID,
        depositAmount: topupAmount,
        //requestedBy: getCurrentUser(),
        requestedBy: currentUser,
        type: 'deposit',
        status: 'Pending'
      };

      // DEBUG INFO
      //console.log('currentUser is now: ' + currentUser);
    
      // Submit the query
      await API.graphql({
        query: createDeposits,
        variables: {input: deposit},
        authMode:'AMAZON_COGNITO_USER_POOLS'
      });

      successToast();
      setTopupAmount('');
    } catch (error) {
      errorToast();
    }
  }

  const creditcardToast = (withThisMessage) => {
    toast.success(withThisMessage, {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: false,
      autoClose: false
  });
  }

  const creditcardErrorToast = (withThisMessage) => {
    toast.error(withThisMessage, {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: false,
      autoClose: false
  });
  }

  const successToast = () => {
    toast.success(successDepositMessage, {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: false,
      autoClose: false
  });
  }

  const errorToast = () => {
    toast.error(errorDepositMessage, {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: false,
      autoClose: false
  });
  }

  function isMalicious(validateThis) {
    if (regex4Malicious.test(validateThis)) {
      errorToast();

      // DEBUG INFO
      //console.log(validateThis + ' is NOT VALID');
      //return false;
      return true;
    }

    return false;
  }

  function isEmpty(validateThis) {
    if (regex4Empty.test(validateThis)) {
      errorToast();

      // DEBUG INFO
      //console.log(validateThis + ' is NOT VALID');
      //return false;
      return true;
    }

    return false;
  }

  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function ChangeTopUpAmount(e) {
    setTopupAmount(e.target.value);
  }

  function HandleTopUpChange(e) {
    setTopupCreditCardAmount(e.target.value);
    setPaymentPlan(e.target.value);
  }

  function CreditCardTopUp() {
    // DEBUG INFO
    //console.log('this is the chosen amount: ' + topupCreditCardAmount);
    if (topupCreditCardAmount === '126') {
      window.open('https://buy.stripe.com/9AQ2bC5g80Yl5NueUZ', '_blank');
      creditcardToast('Your deposit request will be processed');
    }
    else if (topupCreditCardAmount === '315') {
      window.open('https://buy.stripe.com/28o5nOcIA9uR2Bi4gm', '_blank');
      creditcardToast('Your deposit request will be processed');
    }
    else if (topupCreditCardAmount === '630') {
      window.open('https://buy.stripe.com/aEU2bCdMEbCZdfWfYY', '_blank');
      creditcardToast('Your deposit request will be processed');
    }
    else if (topupCreditCardAmount === '1260') {
      window.open('https://buy.stripe.com/7sI9E47og8qN6Ry8wx', '_blank');
      creditcardToast('Your deposit request will be processed');
    }
    else if (topupCreditCardAmount === '1890') {
      window.open('https://buy.stripe.com/aEUaI8fUMayVek0fZ0', '_blank');
      creditcardToast('Your deposit request will be processed');
    }
    else if (topupCreditCardAmount === '3150') {
      window.open('https://buy.stripe.com/5kA7vW0ZS6iFa3K147', '_blank');
      creditcardToast('Your deposit request will be processed');
    }
    else if (topupCreditCardAmount === '6300') {
      window.open('https://buy.stripe.com/8wMaI8aAsbCZ6Ry28c', '_blank');
      creditcardToast('Your deposit request will be processed');
    }
    else {
      creditcardErrorToast('Error requesting top up, please refresh the page or contact customer service for assistance');
    }
  }

  return (
    <>
    <ToastContainer
    newestOnTop
    closeOnClick
    pauseOnFocusLoss
    pauseOnHover
    className='toastMessage'
    />
    
    <div className='game-container-inner'>
        <div className='gamehero-container-back'>
            <a href='/assets'>
                <button className='close-button'>
                    <AiOutlineArrowLeft />Back
                </button>
            </a>
        </div>
    </div>

    <div className='assets-container'>
        <div className='navbar-container-inner'>
        <span className='deposit-text'>DEPOSIT</span>
        </div>
    </div>
    
    <div className='assets-container'>
      <div className='assetshero-col'>
      
            <p className='withdraw-p'>Note: Only deposit USDT using TRC20 (Tron) network</p>

            <span className='withdraw-input-text-label'><span className='redinput'>*</span> USDT Amount</span>
            <input
            type='text'
            className='withdraw-input-text'
            value={topupAmount}
            onChange={ChangeTopUpAmount}
            />
            <button className='withdraw-input-button' onClick={SubmitDeposit}>Submit</button>
            
            <p className='withdraw-p'>Send {displayTopupAmount} USDT to the wallet address below </p>
              <p className='withdraw-p-walletaddress'>TAGJpbMdeURGceKBLS6NuqgM7rDR4W7VM8</p>
            
            <p className='withdraw-p'>Send proof of transaction to customer service at telegram - 
              <a href='http://t.me/aw3gcs' className='withdraw-link' target='_blank'> t.me/aw3gcs</a> 
            </p>
            <p></p>
            
            <p></p>
              <span className='withdraw-p'>- Operation hours from 8AM - 4PM (GMT)</span>
              <span className='withdraw-p'>- Deposit requests processing time may take up to 24 hours after receiving proof of transfer</span>
              <span className='withdraw-p'>- Deposit requests via credit card will not be displayed in the assets page</span>
              <hr className='divider-white' />
              <p></p>
      </div>
    </div>
    </>
  )
}

export default Deposit;