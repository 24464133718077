import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/header.css';
import SiteBanner from '../../assets/site-banner.png';
import { BiSupport } from 'react-icons/bi';

function Header() {
    return (
        <>
          <nav className='header'>
            <div className='header-container'>
              <Link to='/home' className='header-logo'>
                <img src={SiteBanner} className='header-logo-img' />
              </Link>

              <div className='header-spacer'></div>

              <a href='http://t.me/aw3gcs' target='_blank' className='header-cs-a'>
                <BiSupport className='header-cs-b'/>
              </a>
            </div>
          </nav>
        </>
      );
}

export default Header;