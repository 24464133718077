// components/Login.js
import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { Hub } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import GameRulesLightBox from '../layouts/lightbox-gamerules';

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';
import Navbar from '../layouts/navbar';

import '../../css/login.css';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  let from = location.state?.from?.pathname || '/home';

  const regex4Digits = /[0123456789]/g;

  const [searchParams, setSearchParams] = useSearchParams();
  let refKeyInput = '';
  let refKeySanitized = '';

  // The below try catch is to redirect users if they navigate to /register without having a refkey param
  try {
    refKeyInput = searchParams.get('refkey');
    refKeySanitized = refKeyInput.split("?", 1);
    
  } catch (e) {
    // This was messing up stuff
    //window.location.replace("/login?refkey=");
  }

  useEffect(() => {
    
  }, []);

  const formFields = {
    signUp: {
      email: {
        order: 1,
        placeholder: '',
        labelHidden: false,
        label: 'Email'
      },
      password: {
        order: 2,
        placeholder: '',
        labelHidden: false,
        label: 'Password'
      },
      confirm_password: {
        order: 3,
        placeholder: '',
        labelHidden: false,
        label: 'Confirm Password'
      },
      // Using nickname because its a 'known attribute' in Cognito
      // This way we don't need to create a custom one to store what ref code the user used during registration
      // NOTE: THIS IS THE REFERRAL CODE ENTERED BY THE USER !!!
      nickname: {
        order: 4,
        label: 'Referral Code',
        placeholder: '',
        isRequired: true,
        defaultValue: refKeySanitized
      }
    },
   }

   const services = {
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();

      // Using Cognito's profile attribute as a cheat
      // This way we don't need to create/use custom ones that adds on to the complexity of the app
      // In reality, this should be isFirstTopUp
      attributes.profile = 'true';

      // Using Cognito's zoneinfo attribute as a cheat
      // This way we don't need to create/use custom ones that adds on to the complexity of the app
      // In reality, this should be gamePoints
      attributes.zoneinfo = '0';

      // Using Cognito's website attribute as a cheat
      // This way we don't need to create/use custom ones that adds on to the complexity of the app
      // In reality, this should be amountPlayed
      attributes.website = '0';

      // In reality, this should be whether the user is allowed to play today, will reset via on a time triggered Lambda function
      // Once they win, it will be set to disallow
      attributes.address = 'allow';

      // In reality, this should be the Withdrawal Count Down Day
      attributes.picture = '99';
      
      // In reality, this should be the total commission earned
      attributes.name = '0';

      // In reality, this should be the user's expected bet amount that is according to their game plan
      if (attributes.gender === '126') {
        attributes.locale = '2';
      }
      else if (attributes.gender === '315') {
        attributes.locale = '5';
      }
      else if (attributes.gender === '630') {
        attributes.locale = '10';
      }
      else if (attributes.gender === '1260') {
        attributes.locale = '20';
      }
      else if (attributes.gender === '1890') {
        attributes.locale = '30';
      }
      else if (attributes.gender === '3150') {
        attributes.locale = '50';
      }
      else if (attributes.gender === '6300') {
        attributes.locale = '100';
      }

      // DEBUG INFO
      //console.log('username is: ' + username);
      //console.log('attributes.email is: ' + attributes.email);
      //console.log('attributes.profile is: ' + attributes.profile);
      //console.log('attributes.gender is: ' + attributes.gender);
      //console.log('typeof attributes.gender is: ' + typeof attributes.gender);

      /*
      if (attributes.gender === '') {
        //console.log('im here');
        //return('Game Plan cannot be empty');
        throw new Error('Game Plan cannot be empty');
      }
      else if (!regex4Digits.test(attributes.gender)) {
        throw new Error('Invalid Game Plan');
      }
      */

      if (attributes.gender === '126' || attributes.gender === '315' || attributes.gender === '630' || attributes.gender === '1260' || attributes.gender === '1890' || attributes.gender === '3150' || attributes.gender === '6300') {
        return Auth.signUp({
          username,
          password,
          attributes,
        })
      }

      else {
        throw new Error('Game Plan must be either 126, 315, 630, 1260, 1890, 3150 or 6300');
      }
    },

    async handleSignIn(formData) {
      let { username, password} = formData;
      window.localStorage.clear();

      try {
        const signInResult = await Auth.signIn({
          username,
          password,
        })
      } catch (e) {
        throw new Error(e);
      }
    }
  };
  
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
    // Using the below code to detect the action of signing in WHILST the user on the /register page
    // Because once they signUp, a signIn event is automatically done, hence redirecting them to /home
    Hub.listen('auth', (data) => {
      const event = data.payload.event;
      //console.log('event:', event);
      if (event === "signIn") {
        //console.log('event:', event);
        window.location.replace("/home");
      }
    });

  }, [route, navigate, from]);


  return (
    <>
    <div className='home-container'>
        <div className='navbar-container'>
            <Navbar />
        </div>
    </div>

    <View className="auth-wrapper">
      <Authenticator
        className='authenticatorCSS'
        initialState='signIn'
        formFields={formFields}
        services={services}
        components={{
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();

              return (
                <>
                  {/* Re-use default `Authenticator.SignUp.FormFields` */}
                  <Authenticator.SignUp.FormFields />
  
                  {/* Append & require Terms & Conditions field to sign up  */}
                  { /* <input type='text' name='gender' id='gender' />
                  <input type="radio" value="Male" name="gender" /> Male
                  <input type="radio" value="Female" name="gender" /> Female
                  <input type="radio" value="Other" name="gender" /> Other
                  */
                  }
                  { /* Cognito doesn't seem to like select dropdown boxes, if we use this, we don't get an email with the code */
                  /*
                  <select name='gender' id='gender'>
                    <option value='630'>630</option>
                    <option value='1260'>1260</option>
                    <option value='1890'>1890</option>
                    <option value='3150'>3150</option>
                    <option value='6300'>6300</option>
                  </select>
                  */
                  }
                  <span>Input Your Game Plan <a className='login-gamerules' onClick={() => setLightBoxDisplay(true)}>What is this?</a></span>
                  <select className='login-gamerules-input' name='gender' id='gender' defaultValue='126'>
                    <option value='126'>126</option>
                    <option value='315'>315</option>
                    <option value='630'>630</option>
                    <option value='1260'>1260</option>
                    <option value='1890'>1890</option>
                    <option value='3150'>3150</option>
                    <option value='6300'>6300</option>
                  </select>
                </>
              );
            },
          },
        }}
      >
      </Authenticator>
    </View>
    <GameRulesLightBox trigger={lightboxDisplay} setTrigger={setLightBoxDisplay} />
    </>
  );
}