import React, { useState, useEffect } from 'react';
import GameRules from '../../assets/game-rules.png';
import '../../css/home.css';

function LightBoxGameRules(props) {
    
    return (props.trigger) ? (
    <>
    <div className='homeBanner-lightbox'>
    <img src={GameRules} onClick={() => props.setTrigger(false)} className='homeBanner-lightboxImage' />
    </div>
    </>
  ) : "";
}

export default LightBoxGameRules;