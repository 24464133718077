import React from 'react';
import CoinRainGif from '../../assets/coinRain.gif';
import MoneyFlyGif from '../../assets/moneyFly.gif';

function CoinRain(props) {
    return (props.trigger) ? (
        <>
        <div className='homeBanner-lightbox-betresult'>
          <img src={props.gifLoad === 'win' ? CoinRainGif : MoneyFlyGif} className='homeBanner-lightboxImage' />
        </div>
        </>
      ) : "";
}

export default CoinRain;