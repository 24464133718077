import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/admin.css';
import { API, graphqlOperation } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

function AdminListDeposit(props) {
    const isDeposit = props.isDeposit;
    const depositList = props.depositList;
    const [ depositStatus, setDepositStatus ] = useState('');

    async function updateUser() {
        let apiName = 'AdminQueries';
        let path = '/adminUpdateUserAttributes';
        let attribute = [{"Name": "zoneinfo", "Value": "10000"}];
        let myInit = {
            body: {
                "username" : "jo11pentesting@hotmail.com",
                "attribute" : attribute,
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        return await API.post(apiName, path, myInit);
    }

    async function depositUpdate(updateThisDeposit, withThisStatus) {
        // DEBUG INFO
        //console.log('updating deposit: ' + updateThisDeposit + ' with this status: ' + withThisStatus);
        
        try {
            const updatedDeposit = { id: updateThisDeposit, status: withThisStatus};
            await API.graphql({
                query: mutations.updateDeposits,
                variables: {input: updatedDeposit},
                authMode:'AMAZON_COGNITO_USER_POOLS'
            });
            
            alert('Status update success...');
        } catch (err) {
            // DEBUG INFO
            //console.log('error updating order: ' + err);
            alert('Status update failed...');
        }
    }
    
    function handleDepositSelection(e) {
        setDepositStatus(e.target.value);
    }

    if (isDeposit) {
        return(
            <>
            <h4>SECTION FOR DEPOSITS</h4>
                    <table id='deposit-table'>
                        <tbody>
                            <tr>
                                <th>REF</th>
                                <th>Deposit Amount</th>
                                <th>Requested By</th>
                                <th>Status</th>
                                <th>ACTION</th>
                            </tr>
                            {
                                depositList.map((deposit, index) => (
                                    <tr key={index}>
                                        <td>{deposit.id}</td>
                                        <td>{deposit.depositAmount}</td>
                                        <td>{deposit.requestedBy}</td>
                                        <td>
                                            <select name='statusSelection' onChange={handleDepositSelection}>
                                                <option value={deposit.status}>{deposit.status}</option>
                                                <option value='Pending'>Pending</option>
                                                <option value='Done'>Done</option>
                                                <option value='Cancelled'>Cancelled</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                depositUpdate(deposit.id, depositStatus);
                                            }}>Submit</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
            </>
        )
    }
}

export default AdminListDeposit;