import React, {useState, useEffect} from 'react';
import '../../css/home.css';
import HomeBannerPartners from '../../assets/homebanner-partners.jpg';
import HomePlayNow from '../../assets/home-playnow.png';
import HomeBanner1 from '../../assets/homebanner-1.png';
import HomeBanner2 from '../../assets/homebanner-2.png';

function HomeHero() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  const [lightboxDisplay, setLightBoxDisplay] = useState(false);

  const colors = [{ image: HomeBanner1 }, { image: HomeBanner2 }];
  const delay = 4000;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <>
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {colors.map((slide, index) => (
          <img key={index} className='slideshow-image' src={slide.image}/>
        ))}
      </div>

      <div className="slideshowDots">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>

    <div className='home-container'>
      <a href='/game/tron'>
        <div className='homehero-container'>
          <img src={HomePlayNow} className='homeBanner' />
        </div>
      </a>
    </div>

    <div className='home-container'>
      <div className='homehero-container'>
        <img src={HomeBannerPartners} className='homeBanner' />
      </div>
    </div>
    </>
  );
}

export default HomeHero;