import React, { useState, useEffect } from 'react';
import '../../css/assets.css';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { createWithdraws } from '../../graphql/mutations';

import { ToastContainer, toast } from 'react-toastify';

function Withdraw(props) {
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  
  let successWithdrawMessage = '';
  const errorWithdrawMessage = 'Request failed, refresh thie page and try again';

  const regex4SpecialCharacters = /[|!@#$%^&*()_+\-\\\[\]=\|<>?`~±§£™\ ]/g;
  const regex4Malicious = /[a-z]|[A-Z]|[|!@#$%^&*()_+\-\\\[\]=\|<>?`~±§£™\ ]/g;
  const regex4Empty = /^\s*$/g;

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      //setCurrentUser(user.attributes.email.split("@", 1));
      setCurrentUser(user.attributes.email);
    })
    .catch(err => {});
  }, []);

  function SubmitWithdraw() {
    // DEBUG INFO
    //console.log('submit withdraw button clicked');
    //console.log('withdrawAmount is: ' + withdrawAmount);
    //console.log('walletAddress is: ' + walletAddress)

    if(isMalicious(withdrawAmount) || isSpecialCharacters(walletAddress) || isEmpty(withdrawAmount) || isEmpty(walletAddress)) {
      return
    }

    createWithdraw();
  }

  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async function createWithdraw() {
    try {
      // Create values for the query
      let uniqueID = makeid(8);

      successWithdrawMessage = 'Contact customer service to process your withdrawal';
      
      // Build the query
      const withdraw = {
        id: uniqueID,
        withdrawAmount: withdrawAmount,
        walletAddress: walletAddress,
        requestedBy: currentUser,
        type: 'withdraw',
        status: 'Pending'
      };
    
      // Submit the query
      await API.graphql({
        query: createWithdraws, 
        variables: {input: withdraw},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      successToast();
      setWithdrawAmount('');
      setWalletAddress('');
    } catch (error) {
      errorToast();
    }
  }

  const successToast = () => {
    toast.success(successWithdrawMessage, {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: false,
      autoClose: false
  });
  }

  const errorToast = () => {
    toast.error(errorWithdrawMessage, {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: false,
      autoClose: false
  });
  }

  function ChangeWalletAddress(e) {
    setWalletAddress(e.target.value);
  }

  function ChangeWithdrawAmount(e) {
    setWithdrawAmount(e.target.value);
  }

  function isSpecialCharacters(validateThis) {
    if (regex4SpecialCharacters.test(validateThis)) {
      errorToast();

      // DEBUG INFO
      //console.log(validateThis + ' is NOT VALID');
      //return false;
      return true;
    }

    return false;
  }

  function isMalicious(validateThis) {
    if (regex4Malicious.test(validateThis)) {
      errorToast();

      // DEBUG INFO
      //console.log(validateThis + ' is NOT VALID');
      //return false;
      return true;
    }

    return false;
  }

  function isEmpty(validateThis) {
    if (regex4Empty.test(validateThis)) {
      errorToast();

      // DEBUG INFO
      //console.log(validateThis + ' is NOT VALID');
      //return false;
      return true;
    }

    return false;
  }

  return (
    <>
    <ToastContainer
    newestOnTop
    closeOnClick
    pauseOnFocusLoss
    pauseOnHover
    className='toastMessage-withdraw'
    />

<div className='game-container-inner'>
        <div className='gamehero-container-back'>
            <a href='/assets'>
                <button className='close-button'>
                    <AiOutlineArrowLeft />Back
                </button>
            </a>
        </div>
    </div>

    <div className='assets-container'>
        <div className='navbar-container-inner'>
        <span className='deposit-text'>WITHDRAW</span>
        </div>
    </div>

    <div className='assets-container'>
        <div className='assetshero-col'>
            
            <p className='withdraw-p'>Note: 5 USDT withdrawal fees apply to every withdrawal </p>
            
            <span className='withdraw-input-text-label'><span className='redinput'>*</span> USDT Amount</span>
            <input
            type='text'
            className='withdraw-input-text'
            value={withdrawAmount}
            onChange={ChangeWithdrawAmount}
            />
            
            <span className='withdraw-input-text-label'>USDT Wallet Address (TRC-20)</span>
            <input
            type='text'
            className='withdraw-input-text'
            value={walletAddress}
            onChange={ChangeWalletAddress}
            />

            <button className='withdraw-input-button' onClick={SubmitWithdraw}>Submit</button>

            <p className='withdraw-p'>Contact customer service at telegram - 
              <a href='http://t.me/aw3gcs' className='withdraw-link' target='_blank'> t.me/aw3gcs</a> 
            </p>
            <p></p>
              <span className='withdraw-p'>- Operation hours from 8AM - 4PM (GMT)</span>
              <span className='withdraw-p'>- Withdraw requests processing time may take up to 1 hour</span>
              <hr className='divider-white' />
              <p></p>
            </div>
    </div>
    </>
  )
}

export default Withdraw;