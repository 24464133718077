import React from 'react';
import '../../css/home.css';
import HomeHero from '../layouts/homehero';
import Navbar from '../layouts/navbar';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, Heading, View } from '@aws-amplify/ui-react';

function Home() {
  return (
    <>
    <div className='home-container'>
        <div className='navbar-container'>
            <Navbar />
        </div>
    </div>

    <div className='homehero-container'>
      <HomeHero />
    </div>
    
    </>
  );
}

export default Home;