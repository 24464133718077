/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWithdraws = /* GraphQL */ `
  query GetWithdraws($id: ID!) {
    getWithdraws(id: $id) {
      id
      type
      createdAt
      withdrawAmount
      walletAddress
      status
      requestedBy
      updatedAt
      owner
    }
  }
`;
export const listWithdraws = /* GraphQL */ `
  query ListWithdraws(
    $filter: ModelWithdrawsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWithdraws(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        withdrawAmount
        walletAddress
        status
        requestedBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const withdrawsByDate = /* GraphQL */ `
  query WithdrawsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWithdrawsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    withdrawsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        withdrawAmount
        walletAddress
        status
        requestedBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDeposits = /* GraphQL */ `
  query GetDeposits($id: ID!) {
    getDeposits(id: $id) {
      id
      type
      createdAt
      depositAmount
      status
      requestedBy
      updatedAt
      owner
    }
  }
`;
export const listDeposits = /* GraphQL */ `
  query ListDeposits(
    $filter: ModelDepositsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeposits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        depositAmount
        status
        requestedBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const depositsByDate = /* GraphQL */ `
  query DepositsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepositsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    depositsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        depositAmount
        status
        requestedBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getClientUsers = /* GraphQL */ `
  query GetClientUsers($id: ID!) {
    getClientUsers(id: $id) {
      id
      upline
      isFirstTopUp
      referredUserGamePlan
      createdAt
      updatedAt
    }
  }
`;
export const listClientUsers = /* GraphQL */ `
  query ListClientUsers(
    $filter: ModelClientUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        upline
        isFirstTopUp
        referredUserGamePlan
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBets = /* GraphQL */ `
  query GetUserBets($id: ID!) {
    getUserBets(id: $id) {
      user
      betBlock
      betResult
      betAmount
      createdAt
      id
      updatedAt
      owner
    }
  }
`;
export const listUserBets = /* GraphQL */ `
  query ListUserBets(
    $filter: ModelUserBetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        user
        betBlock
        betResult
        betAmount
        createdAt
        id
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const betsByDate = /* GraphQL */ `
  query BetsByDate(
    $user: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserBetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    betsByDate(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user
        betBlock
        betResult
        betAmount
        createdAt
        id
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
