import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import Header from './components/layouts/header';
import Home from './components/pages/home';
import Announcement from './components/pages/announcement';
import Assets from './components/pages/assets';
import Profile from './components/pages/profile';
import Game from './components/pages/game';
import GameTron from './components/pages/game-tron';
import GameTronCont from './components/pages/game-tron-cont';
import GameEOS from './components/pages/game-eos';
import Register from './components/pages/register';
import AdminPanel from './components/pages/admin';
import Deposit from './components/layouts/deposit';
import Withdraw from './components/layouts/withdraw';
import DownLine from './components/layouts/downline';
import PasswordChange from './components/layouts/passwordchange-form';
import BlogPostCasinoGame from './components/pages/blogpost-casinogame';
import { Login } from './components/pages/login';
import { RequireAuth } from './components/pages/requireAuth';
import Preview from './components/pages/preview';
import { Authenticator } from '@aws-amplify/ui-react';

function App() {
  return (
    <>
    <Authenticator.Provider>
      <Router>
        <Header />
        <Routes>
        <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/assets' element={<RequireAuth><Assets /></RequireAuth>} />
          <Route path='/profile' element={<RequireAuth><Profile /></RequireAuth>} />
          <Route path='/login' element={<Login />} />
          <Route path='/game/tron' element={<RequireAuth><GameTron /></RequireAuth>} />
          <Route path='/game/tron/play' element={<RequireAuth><GameTronCont /></RequireAuth>} />
          <Route path='/wsx' element={<RequireAuth><AdminPanel /></RequireAuth>} />
          <Route path='/deposit' element={<RequireAuth><Deposit /></RequireAuth>} />
          <Route path='/withdraw' element={<RequireAuth><Withdraw /></RequireAuth>} />
          <Route path='/downline' element={<RequireAuth><DownLine /></RequireAuth>} />
          <Route path='/passwordchange' element={<RequireAuth><PasswordChange /></RequireAuth>} />
        </Routes>
      </Router>
      </Authenticator.Provider>
    </>
  );
}

export default App;
