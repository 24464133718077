import React from 'react';
import '../../css/announcement.css';
import '@aws-amplify/ui-react/styles.css';
import { GrAnnounce } from 'react-icons/gr';
import AnnouncementHero from '../layouts/announcementhero';
import Navbar from '../layouts/navbar';

import { Authenticator } from '@aws-amplify/ui-react';

function Announcement() {
  return (
    <>
    <div className='announcement-container'>
        <div className='navbar-container'>
            <Navbar />
        </div>
    </div>
    
    <div className='announcement-container'>
        <div className='navbar-container'>
        <GrAnnounce className='navbar-icon' size={26} /> ANNOUNCEMENT
        </div>
    </div>

    <Authenticator>
    <div className='announcement-container'>
        <div className='announcementhero-container'>
            <AnnouncementHero />
        </div>
    </div>
    </Authenticator>
    </>
  );
}

export default Announcement;