import React, { useState, useEffect, useRef } from 'react';
import '../../css/profile.css';
import { Authenticator } from '@aws-amplify/ui-react';
import PasswordChange from './passwordchange-form';
import DownLine from './downline';

import { RiLockPasswordLine } from 'react-icons/ri';
import { MdOutlineContactSupport } from 'react-icons/md';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { FiCopy } from 'react-icons/fi';
import { VscTypeHierarchySub } from 'react-icons/vsc';

import { Auth } from 'aws-amplify';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import "bootstrap/dist/css/bootstrap.css";


function ProfileHero() {
    const { user, signOut } = Authenticator((context) => [context.user]);
    const [ currentUser, setCurrentUser ] = useState('');
    

    const [ withdrawAvailability, setWithdrawAvailability ] = useState('');

    const [ userGamePoint, setUserGamePoint ] = useState('');
    const [ userCommissionEarned, setUserCommissionEarned ] = useState('');
    
    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            //setCurrentUser(user.attributes.email.split("@", 1));
            setCurrentUser(user.attributes.email);
          
            let userAvailableWithAmount = parseFloat(user.attributes.zoneinfo) - parseFloat(user.attributes.gender);
            if (user.attributes.picture === '0' && user.attributes.website > (user.attributes.gender * 5)) {
                userAvailableWithAmount = parseFloat(userAvailableWithAmount) + parseFloat(user.attributes.gender);
            }
        
            if (userAvailableWithAmount < 0) {
                userAvailableWithAmount = '0';
            }
        
            userAvailableWithAmount = parseFloat(userAvailableWithAmount) + parseFloat(user.attributes.name);
            userAvailableWithAmount = parseFloat(userAvailableWithAmount).toFixed(2);
            setWithdrawAvailability(userAvailableWithAmount);

            setUserGamePoint(user.attributes.zoneinfo);
            setUserCommissionEarned(user.attributes.name);
            //setTotalCommEarning(user.attributes.name);
        })
        .catch(err => {});
    }, []);

    function copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    } 

    const popover = (
        <Popover id="popover-basic">
          <Popover.Body>
            Referral link copied !
          </Popover.Body>
        </Popover>
      );

    return (
        <>
        <Authenticator>
        {({ user }) => (
            <div className='profile-container'>
                <div className='profilehero-row'>
                    <p className='profilekey'>Username</p>
                    <p className='profilevalue'>{user.attributes.email.split("@", 1)}</p>
                </div>
                <hr className='divider' />
            
                <div className='profilehero-row'>
                    <p className='profilekey'>Game Points</p>
                    <p className='profilevalue'>$ {(parseFloat(userGamePoint) + parseFloat(userCommissionEarned)).toFixed(2)}</p>
                </div>
                <hr className='divider' />

                <div className='profilehero-row'>
                    <p className='profilekey'>Withdrawal Availability</p>
                    <p className='profilevalue'>$ {withdrawAvailability}</p>
                </div>
                <hr className='divider' />

                <div className='profilehero-row'>
                    <p className='profilekey'>Total Play</p>
                    <p className='profilevalue'>$ {(parseFloat(user.attributes.website)).toFixed(2)}</p>
                </div>
                <hr className='divider' />

                <div className='profilehero-row'>
                    <p className='profilekey'>Referal Link</p>
                    <p className='profilevalue'>
                        <input
                            type="text"
                            value={'https://' + window.location.hostname + '/login?refkey=' + user.attributes.email.split("@", 1)}
                            className='profile-ref-input'
                            disabled
                        />
                        <OverlayTrigger trigger="click" rootClose={true} placement="top" overlay={popover}>
                        <button
                            onClick={() => {
                                copyToClipboard('https://' + window.location.hostname + '/login?refkey=' + user.attributes.email.split("@", 1));
                            }}
                            className='signout-button'
                        >
                            <span className='copytoclipboardbutton'><FiCopy /> Copy To Clipboard</span>
                            
                        </button>
                        </OverlayTrigger>
                    </p>
                </div>
            </div>
        )}
        </Authenticator>

        <Authenticator>
        {({ signOut }) => (
            <div className='profile-container'>
                <div className='profile-container-col'>

                    <a href='/downline'>
                <button className='passwordchange-button'>
                    <p><VscTypeHierarchySub /> My Downline</p>
                </button>
                </a>
                <hr className='divider-full' />

                <a href='/passwordchange'>
                <button className='passwordchange-button'>
                    <p><RiLockPasswordLine /> Change Password</p>
                </button>
                </a>
                <hr className='divider-full' />                

                <a href='http://t.me/aw3gcs' target='_blank'>
                    <p className='profile-contact-link'><MdOutlineContactSupport /> Contact Customer Service</p>
                </a>
                <hr className='divider-full' />
            
                <p><button onClick={signOut} className='signout-button'><RiLogoutBoxRLine /> Logout</button></p>
                </div>
            </div>
        )}
        </Authenticator>
        </>
  );
}

export default ProfileHero;