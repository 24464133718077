import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/admin.css';
import { API, graphqlOperation } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import AdminListDeposit from './admin-listdeposit';
import AdminListWithdraw from './admin-listwithdraw';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

function AdminHero(props) {
    const isAdmin = props.isAdmin;
    const [ userList, setUserList ] = useState([]);
    const [ depositList, setDepositList ] = useState([]);
    const [ withdrawlList, setWithdrawlList ] = useState([]);

    const [ adminTopUpInput, setAdminTopUpInput ] = useState('');
    
    const [ isWithdraw, setIsWithdraw ] = useState(false);
    const [ isDeposit, setIsDeposit ] = useState(false);

    useEffect(() => {
    }, []);

    async function updateUser(updateThisUser, withThisValue, onThisAttribute) {
        let apiName = 'AdminQueries';
        let path = '/adminUpdateUserAttributes';
        let attribute = [{"Name": onThisAttribute, "Value": withThisValue}];
        let myInit = {
            body: {
                "username" : updateThisUser,
                "attribute" : attribute,
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        console.log('Update ' + updateThisUser + ' ' + onThisAttribute + ' ' + ' with ' + withThisValue + ' successful...');
        return await API.post(apiName, path, myInit);
    }

    async function userGamePointUpdate(updateThisUser, withThisValue) {
        // DEBUG INFO
        console.log('going to update this user: ' + updateThisUser.email + ' with this value: ' + withThisValue);
        //console.log(updateThisUser);
        
        // Processing a deposit, simply do an addition and update user's game points
        if (withThisValue > '0') {
            const calcUpdateValue = parseFloat(updateThisUser.gamePoint) + parseFloat(withThisValue);
            
            updateUser(updateThisUser.email, calcUpdateValue.toString(), 'zoneinfo');

            // If true, means this is the user's first topup
            if (updateThisUser.userIsFirstTopUp === 'true') {
                console.log(updateThisUser.email + ' is his first time top up...');
                // Loop through the available userList and find this user's upline
                for (var a = 0 ; a < userList.length ; a++) {
                    if (userList[a].email.split("@", 1)[0] === updateThisUser.userNickname) {
                        console.log('the upline user is: ' + userList[a].email);
                        console.log('currently, the upline has comm earned: ' + userList[a].commEarned);
                        let uplineCommEarned = 0.0;

                        let updateUpLineCommEarned = 0;

                        // If they are topping up less than their gamePlan amount, use their top up amount
                        if (withThisValue < updateThisUser.gamePlan) {
                            updateUpLineCommEarned = parseFloat(userList[a].commEarned) + parseFloat((withThisValue * 0.2));
                            uplineCommEarned = parseFloat(withThisValue);
                            console.log('updateUpLineCommEarned is now: ' + updateUpLineCommEarned);
                        }

                        // If they are topping up more than their gamePlan amount, use their game plan amount
                        if (withThisValue >= updateThisUser.gamePlan) {
                            updateUpLineCommEarned = parseFloat(userList[a].commEarned) + parseFloat((updateThisUser.gamePlan * 0.2));
                            uplineCommEarned = parseFloat(updateThisUser.gamePlan);
                            console.log('updateUpLineCommEarned is now: ' + updateUpLineCommEarned);
                        }

                        // and update the name (comm earned) Cognito attribute of THAT user (email starts with this user's nickname Cognito attr)
                        updateUser(userList[a].email, updateUpLineCommEarned.toString(), 'name');

                        // Finally, we need to insert an entry in the ClientUsers GraphQL table 
                        const clientUserEntry = {
                            id: updateThisUser.email,
                            upline: userList[a].email,
                            isFirstTopUp: 'false',
                            // Using withThisValue instead of gamePlan, because they might not top up their actual game plan amount
                            // Was updateThisUser.gamePlan, now using withThisValue
                            referredUserGamePlan: uplineCommEarned
                        };
                        await API.graphql({
                            query: mutations.createClientUsers,
                            variables: {input: clientUserEntry},
                            authMode:'AMAZON_COGNITO_USER_POOLS'
                        });

                        break;
                    }
                }

                // and set this value to false
                updateUser(updateThisUser.email, 'false', 'profile');
            }
        }
        
        // Processing a withdraw, slightly more complicated
        else if (withThisValue < '0') {
            // DEBUG INFO
            //console.log('withThisValue is: ' + withThisValue);
            console.log('updateThisUser.commEarned is: ' + updateThisUser.commEarned);

            // If the requested amount is less than their commission earned amount
            // simply take it out from their commission earned
            
            const thisValueNoMinus = withThisValue.split("-", 2)[1];
            //console.log('thisValueNoMinus is: ' + thisValueNoMinus);
            //console.log('updateThisUser.commEarned is: ' + updateThisUser.commEarned);
            if (thisValueNoMinus <= updateThisUser.commEarned) {
                const tempSubtract = parseFloat(updateThisUser.commEarned) - parseFloat(thisValueNoMinus);
                updateUser(updateThisUser.email, tempSubtract.toString(), 'name');
            }
            
            // else if, they requested more than what they have in their commission earned, for example:
            // Commission earned = 126
            // Total game points = 400
            // Requested amount = 300
            // This means commission earned will be 0, and total game points will become 400 - (300 - 126) = 226
            else if (thisValueNoMinus > updateThisUser.commEarned) {
                // First set the comm earned for this user to 0
                updateUser(updateThisUser.email, '0', 'name');

                // Then calculate the remaining value after subtracting the value in user's commission earned
                const remainingSubtract = parseFloat(thisValueNoMinus) - parseFloat(updateThisUser.commEarned);
                
                //console.log(updateThisUser);
                //console.log('updateThisUser.gamePoint is: ' + updateThisUser.gamePoint);
                //console.log('remainingSubtract is: ' + remainingSubtract);
                // Then subtract user's final game point with the remainingSubtract
                const gamePointFinalValue = parseFloat(updateThisUser.gamePoint) - parseFloat(remainingSubtract);
                //console.log('gamePointFinalValue is: ' + gamePointFinalValue);
                updateUser(updateThisUser.email, gamePointFinalValue.toString(), 'zoneinfo');
            }
        }

        window.alert('User TOPUP / Withdraw done !!');
    }

    async function listUsers() {
        let apiName = 'AdminQueries';
        let path = '/listUsers';
        let myInit = {
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
    return await API.get(apiName, path, myInit);
    }

    function loadUser() {
        setUserList([]);
        let userGamePlan = '';
        let userGamePoint = '';
        let userEmail = '';
        let userAmountPlayed = '';
        let userWDCD = '';
        let userAvailableWithAmount = '';
        let userCommEarned = '';
        let userIsFirstTopUp = '';
        let userNickname = '';
        
        listUsers().then((x) => {
            // DEBUG INFO
            //console.log("DEBUGINFO: " + x.Users.length);
            //console.log(x.Users[0].Attributes);
            //console.log(x.Users[0].Attributes);
            //console.log(x.Users[0].Attributes[0]["Name"]);
            //console.log(x.Users[0].Attributes[0]["Value"]);
            
            // Process the response data and save it to a proper list
            for (var a = 0 ; a < x.Users.length ; a++) {
                userGamePlan = '';
                userGamePoint = '';
                userEmail = '';
                userAmountPlayed = '';
                userWDCD = '';
                userAvailableWithAmount = '';
                userCommEarned = '';
                userIsFirstTopUp = '';
                userNickname = '';

                for (var b = 0 ; b < x.Users[a].Attributes.length ; b++) {
                    //console.log('Looping x.Users['+a+'].Attributes['+b+']');
                    if (x.Users[a].Attributes[b]["Name"] === 'email') {
                        //console.log(x.Users[a].Attributes[b]["Value"]);
                        userEmail = x.Users[a].Attributes[b]["Value"];
                    }
                    if (x.Users[a].Attributes[b]["Name"] === 'picture') {
                        userWDCD = x.Users[a].Attributes[b]["Value"];
                    }
                    if (x.Users[a].Attributes[b]["Name"] === 'gender') {
                        userGamePlan = x.Users[a].Attributes[b]["Value"];
                    }
                    if (x.Users[a].Attributes[b]["Name"] === 'website') {
                        userAmountPlayed = x.Users[a].Attributes[b]["Value"];
                    }
                    if (x.Users[a].Attributes[b]["Name"] === 'zoneinfo') {
                        userGamePoint = x.Users[a].Attributes[b]["Value"];
                    }
                    if (x.Users[a].Attributes[b]["Name"] === 'name') {
                        userCommEarned = x.Users[a].Attributes[b]["Value"];
                    }
                    if (x.Users[a].Attributes[b]["Name"] === 'profile') {
                        userIsFirstTopUp = x.Users[a].Attributes[b]["Value"];
                    }
                    if (x.Users[a].Attributes[b]["Name"] === 'nickname') {
                        userNickname = x.Users[a].Attributes[b]["Value"];
                    }

                    userAvailableWithAmount = parseFloat(userGamePoint) - parseFloat(userGamePlan);

                    if (userWDCD === '0' && userAmountPlayed > (userGamePlan * 5)) {
                        userAvailableWithAmount = parseFloat(userAvailableWithAmount) + parseFloat(userGamePlan);
                    }

                    if (userAvailableWithAmount < 0) {
                        userAvailableWithAmount = '0';
                    }

                    userAvailableWithAmount = parseFloat(userAvailableWithAmount) + parseFloat(userCommEarned);
                    userAvailableWithAmount = parseFloat(userAvailableWithAmount).toFixed(2);
                }
                const tempUser = {
                                    "email": userEmail,
                                    "WDCD": userWDCD,
                                    "gamePlan": userGamePlan,
                                    "amountPlayed": userAmountPlayed,
                                    "gamePoint": userGamePoint,
                                    "commEarned": userCommEarned,
                                    "userAvailableWithAmount": userAvailableWithAmount.toString(),
                                    "userIsFirstTopUp": userIsFirstTopUp,
                                    "userNickname": userNickname,
                                };
                //console.log(tempUser);
                setUserList(userList => [...userList, tempUser]);
            }
        });
    }

    async function loadWithdraws() {
        setIsWithdraw(true);
        setIsDeposit(false);

        const withdrawsRaw = await API.graphql({
            query: queries.withdrawsByDate,
            variables: {
              sortDirection: 'DESC',
              type: 'withdraw'
            },
            authMode:'AMAZON_COGNITO_USER_POOLS'
        });
        
        const withdrawsArray = withdrawsRaw.data.withdrawsByDate.items;
        setWithdrawlList(withdrawsArray);
    }

    async function loadDeposits() {
        setIsDeposit(true);
        setIsWithdraw(false);

        const depositsRaw = await API.graphql({
            query: queries.depositsByDate,
            variables: {
              sortDirection: 'DESC',
              type: 'deposit'
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        
        const depositsArray = depositsRaw.data.depositsByDate.items;
        setDepositList(depositsArray);
    }

    function handleTopUpAmountChange(e) {
        setAdminTopUpInput(e.target.value);
    }

    if (isAdmin) {
        return (
            <>
            <Authenticator>
            {({ user, signOut }) => (
                <div className='admin-header'>
                <p>Hello admin {user.attributes.email}</p> <button onClick={signOut}> Logout</button>
                </div>
            )}
            </Authenticator>
            <div className='adminhero-container'>
                <div className='adminhero-container-left'>
                    <p>USER ACTION SECTION</p>
                    <button onClick={loadUser} className='admin-loadusers-button'>Load Users</button>
                    <span>*Remarks*</span>
                    <span>WD CD = Withdraw Day Count Down</span>
                    <span>A2W = Able to Withdraw</span>
                    <table id='admin-table'>
                        <tbody>
                            <tr>
                                <th>Email</th>
                                <th>Game Plan</th>
                                <th>Game Points</th>
                                <th>Amount Played</th>
                                <th>Comm Earned</th>
                                <th>WD CD</th>
                                <th>A2W</th>
                                <th>ACTION</th>
                            </tr>
                            {
                                userList.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.email}</td>
                                        <td>{user.gamePlan}</td>
                                        <td>{user.gamePoint}</td>
                                        <td>{user.amountPlayed}</td>
                                        <td>{parseFloat(user.commEarned).toFixed(2)}</td>
                                        <td>{user.WDCD}</td>
                                        <td>{user.userAvailableWithAmount}</td>
                                        <td>
                                            <input
                                                type='text'
                                                id='topUpAmount'
                                                name='topUpAmount'
                                                className='admin-topup-text'
                                                onChange={handleTopUpAmountChange}
                                            />
                                            <button onClick={() => {
                                                //user2Edit = user.email;
                                                userGamePointUpdate(user, adminTopUpInput);
                                            }}>TOPUP</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                

                <div className='adminhero-container-right'>
                    <p>CLICK BUTTON TO LOAD</p>
                    <div className='adminhero-container-row'>
                        <button
                            className='adminhero-loadbuttons'
                            onClick={loadDeposits}
                        >
                            Load Deposits
                        </button>

                        <button
                            className='adminhero-loadbuttons'
                            onClick={loadWithdraws}
                        >
                            Load Withdraws
                        </button>
                    </div>

                    <AdminListDeposit isDeposit={isDeposit} depositList={depositList} />

                    <AdminListWithdraw isWithdraw={isWithdraw} withdrawlList={withdrawlList} />
                    
                </div>

                
            </div>
            
            </>
        );
    }
    else {
        return (
            <>
            </>
        );
    }
}

export default AdminHero;

