import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/admin.css';
import AdminHero from '../layouts/adminhero';
import { Auth } from 'aws-amplify';

function Admin() {
    const [ isAdmin, setIsAdmin ] = useState(false);
  
    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            // DEBUG INFO
            //console.log(user.signInUserSession.accessToken.payload["cognito:groups"]);
            if (user.signInUserSession.accessToken.payload["cognito:groups"].includes("aw3gadmingroup")) {
                setIsAdmin(true);
            }
        })
    }, []);
  
    return (
        <AdminHero isAdmin={isAdmin} />
    );
}

export default Admin;

