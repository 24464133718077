import React, { useState, useEffect } from 'react';
import '../../css/game.css';
import TronLogo from '../../assets/tron-logo.png';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import CoinRainBox from '../layouts/coinrain';

function GameTronCont() {
    // Used for time ticker
    const [ nextRoundTime, setNextRoundTime ] = useState();
    const [ roundEndTime, setRoundEndTime ] = useState('');

    // Used for block and hash updates in various parts of the page
    const [ latestBlock, setLatestBlock ] = useState('');
    const [ latestHash, setLatestHash ] = useState('');
    const [ bettingBlock, setBettingBlock ] = useState('');

    // Used for setting block sync state
    const [ syncBlock, setSyncBlock ] = useState(true);

    // Used for user input, client side controls
    const [ betInputValue, setBetInputValue ] = useState('');
    const [ betInputDisabled, setBetInputDisabled ] = useState(false);

    // Used for checks on whether users won
    const [ userFinalInput, setUserFinalInput ] = useState('');
    //let userFinalInput = '';

    // Static messages
    const [ betMessage, setBetMessage ] = useState('');
    const [ allowBetMessage, setAllowBetMessage ] = useState('');
    const betOpenMessage = 'Playing for block';
    const betCloseMessage = 'Closed for block';
    const syncMessage = 'Syncing blocks, please wait for next round to start...';
    // Moving the below to SubmitBet function, otherwise, it won't update
    //const betSubmitMessage = 'Your submission for this block is ' + userFinalInput;

    const [ betHistory, setBetHistory ] = useState([]);
    const [ currentUser, setCurrentUser ] = useState('');
    const [ userGamePoints, setUserGamePoints ] = useState('');
    const [ userCommEarned, setUserCommEarned ] = useState('');
    const [ userPlayStatus, setUserPlayStatus  ] = useState('');
    const [ userGamePlan, setUserGamePlan] = useState('');
    const [ gameExpectedValue, setGameExpectedValue] = useState('');

    const [ canContPlay, setCanContPlay ] = useState(false);
    const [ userBetMode , setUserBetMode ] = useState('');

    const [ betConfirmationMessage, setBetConfirmationMessage ] = useState('');

    const [ gamePlayButtonContLeftUp, setGamePlayButtonContLeftUp ] = useState('game-play-button-cont-left');
    const [ gamePlayButtonContLeftDown, setGamePlayButtonContLeftDown ] = useState('game-play-button-cont-left');
    const [ gamePlayButtonContRightUp, setGamePlayButtonContRightUp ] = useState('game-play-button-cont-right');
    const [ gamePlayButtonContRightDown, setGamePlayButtonContRightDown ] = useState('game-play-button-cont-right');

    const [ lightboxDisplay, setLightBoxDisplay ] = useState(false);
    const [ loadingGif, setLoadingGif ] = useState('');

    const regex4AlphaNumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/g;
    const regex4Digits = /[0123456789]/g;
    const regex4DigitsCont = /^\d+$/g;
    const regex4Chars = /^(?=.*[a-zA-Z])(?=.*[a-zA-Z])[A-Za-z]+$/g;
    const regex4MultipleDots = /.*\..*\..*/g;
    const regex4FirstChar = /^[a-zA-Z]/g;
    const regex4FirstDigit = /^[0-9]/g;
    const regex4LastChar = /[a-zA-Z]$/g;
    const regex4LastDigit = /[0-9]$/g;
    
    useEffect(() => {
        const id = setTimeout(() => {
            setNextRoundTime(60 - new Date().getSeconds());
            const roundEnd = 30 - new Date().getSeconds();
            // If the round hasn't ended, e.g. roundEnd still has seconds left in it
            // Also, if we are not syncing blocks
            // continue the countdown
            if (roundEnd > 0 && !syncBlock) {
                setRoundEndTime(roundEnd);
                setBetMessage(betOpenMessage);
                //setBetInputDisabled(false);
            }
            // If the round has ended, e.g. roundEnd seconds is anything but positive numbers
            // Also, if we are not syncing blocks
            // stop the countdown
            else if (roundEnd < 1 && !syncBlock) {
                setRoundEndTime(0);
                setBetMessage(betCloseMessage);
                setBetInputDisabled(true);
            }
            
            // when the nextRoundTime countdown reaches 0, e.g. the round has ended
            // Also, if we are not syncing blocks
            // Also, user has not won today
            // initiate a new round
            //console.log('nextRoundTime is now ' + nextRoundTime);
            // setting nextRoundTime <= 1 because sometimes the timer skips a second or 2 if the window is not in focus
            if (nextRoundTime != null && nextRoundTime <= 1 && !syncBlock) {
                //console.log('Next round countdown reached 0, calling startNewRound()...');
                setBetInputDisabled(false);
                startNewRound();
            }
            // DEBUG INFO
            //console.log('Next round time: ' + nextRoundTime);
        }, 1000);
        
        // For first page load only
        // will return the most recent block, with existing hash
        if (latestBlock === '') {
            // This will determine whether we should sync blocks
            // If users join with under 55 seconds left in the round,
            // The bettting block they get will be too far in the future
            // Hence when the time comes to fetch the result hash, the hash will not be available for the betting block

            // If users join between 56 - 60 seconds left of the current round,
            // set the sync block to false to allow next steps to process
            if (nextRoundTime > 55) {
                setSyncBlock(false);
                //console.log('setting this!! ' + userPlayStatus);
                setBetInputDisabled(false);
                onLoadRound();
            }

            // Otherwise, set the syncing message
            else {
                // Disables the input box when syncing blocks
                setBetInputDisabled(true);
                setBetMessage(syncMessage);
            }
            
        }

        return () => clearTimeout(id);
    });

    useEffect(() => {
        refreshUserStats();
    }, []);
    
    // Get the latest block number, which already has a hash
    // DO NOT USE WHAT IS RETURNED AS A NEXT BLOCK NUMBER
    // PLUS 20 TO IT FIRST BEFORE ALLOWING USERS TO BET ON IT
    async function fetchTronBlock() {
        try {
        const responseRaw = await fetch("https://apilist.tronscan.org/api/system/status");
        const response = await responseRaw.json();
        return(response.database.block);
        } catch (e) {
            console.log('an error occurred...');
            setLatestBlock('N/A');
            setLatestHash('ERROR!');
        }
    }
    
    // Fetch the block details, specifically, the hash value
    async function fetchTronBlockDetail(fetchThisBlock) {
        // DEBUG INFO
        //fetchThisBlock = '41798632';
        //console.log('inside fetchTronBlockDetail, fetching this block: ' + fetchThisBlock);
        const responseRaw = await fetch("https://apilist.tronscan.org/api/block?number=" + fetchThisBlock);
        const response = await responseRaw.json();

        // If the tron block doesn't have a hash yet, meaning the transaction volume has gone down
        if (response.total === 0) {
            window.alert('Error fetching block hash because hash has not been allocated by TRON yet. Refreshing page and refunding your previous bet amount');
            
            //window.location.reload();
            window.location.href = "/game/tron/play";
            return('N/A');
        }
        else {
            return(response.data[0].hash);
        }
    }

    function startNewRound() {
        // DEBUG INFO
        //console.log('userFinalInput is: ' + userFinalInput);
        //console.log('fetching winning results of: ' + bettingBlock);

        // Starting a new round, but we need to get the results of the betting block first
        //console.log('Before fetchTronBlockDetail');
        fetchTronBlockDetail(bettingBlock).then((x) => {
            // DEBUG INFO
            //console.log('After fetchTronBlockDetail, it gave me this hash: ' + x);

            // This is to stop the code going into RoundEnd() function if there is no hash for this block
            if (x === 'N/A') {
                // DEBUG INFO
                console.log('Starting new round...');
                //window.location.href = "/game/tron";
                return;
            }
            
            // Fetch and set the hash of the betting block
            setLatestHash(x);
            // Calling RoundEnd() here, to make sure we have the lastestHash value to do the result checking
            RoundEnd(x.substring(62)).then(() => {
                //fetchBetHistory(currentUser).then((y) => {
                //    setBetHistory(y);
                //  });
            });
 
            // Set the lastest block to have the betting block
            setLatestBlock(bettingBlock);

            // TODO - use userFinalInput to somehow validate whether the they have won, and do the necessary processing
            // check Hashie to see how they do it

            // Before starting new round, we should clear all the below values
            // so it looks nice on the client side
            setUserFinalInput('');
            setBetInputValue('');

            // Then fetch a new block + 17 to use as the new betting block
            fetchTronBlock().then((y) => {
                setBettingBlock(y + 17);
            })
          })
    }

    function onLoadRound() {
        // Call fetchBlock, assign latest block number as x
        fetchTronBlock().then((x) => {
            // x may or may not have a hash, which is why we are using x - 1
            setLatestBlock(x - 1);

            // Use current block + 17 for future bets
            // This will be used in the startNewRound function
            setBettingBlock(x + 17);

            // Pass x - 1 to fetchBlockDetail and assign the hash value to y
            fetchTronBlockDetail(x - 1).then((y) => {
              setLatestHash(y);
            })
          })
    }

    function processBetInputChangeValue(userInput) {
        setBetInputValue(userInput.target.value);
    }

    // When a user submits their bet
    function SubmitBet(userInputBetMode) {
        // DEBUG INFO
        //console.log('betInputValue is now: ' + betInputValue);
        // Some checks on user input
        if (parseFloat(betInputValue) > (parseFloat(userGamePoints) + parseFloat(userCommEarned))) {
            setAllowBetMessage('Insufficient Game Points');
            return;
        }

        else if (betInputValue != '' && Math.sign(betInputValue) == 1 && regex4Digits.test(betInputValue) && !regex4MultipleDots.test(betInputValue)) {
            if (betInputValue.includes('.') && betInputValue != '0.5') {
                setAllowBetMessage('Only 0.5 is allowed');
                return;
            }
            setBetInputDisabled(true);
        
            // Since setUserFinalInput uses React's useState and its an async function
            // We need to wrap it around the below state block and do further processing
            // Otherwise it won't pick up the change, since async function needs time to finish
            // P.S. the setUserFinalInput line prior to the block is needed, don't know why...
            setUserFinalInput(betInputValue);
            setUserFinalInput((state) => {
                setUserFinalInput(state);
            });
            
            // Set message for user bet mode here
            if (userInputBetMode === 'Mode1A') {
                setBetConfirmationMessage('Playing for number first then alphabet');
                setGamePlayButtonContRightDown('game-play-button-cont-right-chosen');
            }
            else if (userInputBetMode === 'Mode11') {
                setBetConfirmationMessage('Playing for 2 numbers');
                setGamePlayButtonContLeftUp('game-play-button-cont-left-chosen');
            }
            else if (userInputBetMode === 'ModeAA') {
                setBetConfirmationMessage('Playing for 2 alphabets');
                setGamePlayButtonContRightUp('game-play-button-cont-right-chosen');
            }
            else if (userInputBetMode === 'ModeA1') {
                setBetConfirmationMessage('Playing for alphabet first then number');
                setGamePlayButtonContLeftDown('game-play-button-cont-left-chosen');
            }

            setUserGamePoints(parseFloat(userGamePoints) - parseFloat(betInputValue));
            setAllowBetMessage('');
        }

        else {
            setAllowBetMessage('Invalid input, try again');
        }
        
    }
    
    // Used to do stuff when the round has ended
    /*
     * Only 4 possible modes we will receive:
     * - Mode11
     * - ModeAA
     * - Mode1A
     * - ModeA1 
     */
    async function RoundEnd(checkThisHashValue) {
        // DEBUG INFO
        //console.log('inside RoundEnd function, userFinalInput is: ' + userFinalInput);
        //console.log('first character is: ' + checkThisHashValue.substring(0,1));
        //console.log('second character is: ' + checkThisHashValue.substring(1));
        //console.log('userFinalInput is: ' + userFinalInput);
        //console.log('userBetMode is now: ' + userBetMode);

        // Enabling the below will make sure we always LOSE
        //checkThisHashValue = '00';

        // Enabling the below will make sure we always WIN
        //checkThisHashValue = 'a0';

        let userBet = {};

        // User has placed a bet, so doing the checks
        if (userFinalInput != '') {
            //console.log('running checks on: ' + checkThisHashValue);
            // if the below returns true, the user has won
            if (userBetMode === 'Mode11') {
                if (regex4FirstDigit.test(checkThisHashValue) && regex4LastDigit.test(checkThisHashValue)) {
                    // DEBUG INFO
                    //console.log('this is alpha numeric, setting user to win');
                
                    // Building a win query
                    userBet = {
                        user: currentUser,
                        betBlock: bettingBlock,
                        betAmount: userFinalInput,
                        betResult: 'win'
                    };
                
                    // If they won, add the values back for them
                    // Calculate what we need to update the user's game points
                    // Need to x2 userFinalInput because we have already taken it out from userGamePoints
                    //const tempGamePointUpdate = parseFloat(userGamePoints) + parseFloat(userCommEarned) + (parseFloat(userFinalInput) * 2);
                    const tempGamePointUpdate = parseFloat(userGamePoints) + (parseFloat(userFinalInput) * 2);
                    updateUserGamePoints(tempGamePointUpdate, 'WinBetMode').then(() => {
                        refreshUserStats();
                    });

                    setLoadingGif('win');
                    setLightBoxDisplay(true);
                }
                else {
                    // DEBUG INFO
                    //console.log('this is NOT alpha numeric, setting user to lose');
                
                    // Building a lose query
                    userBet = {
                        user: currentUser,
                        betBlock: bettingBlock,
                        betAmount: userFinalInput,
                        betResult: 'lose'
                    };
                
                    // If they lost, just provide the deducted value, update it, and refresh the user data
                    //console.log('if they lost update with this gamePoint: ' + userFinalInput);
                    updateUserGamePoints(userFinalInput, 'LoseBetMode').then(() => {
                        //console.log('after updateUserGamePoints, firing off refreshUserStats...');
                        refreshUserStats();
                    });

                    setLoadingGif('lose');
                    setLightBoxDisplay(true);
                }
            }
        
            if (userBetMode === 'ModeAA') {
                if (regex4FirstChar.test(checkThisHashValue) && regex4LastChar.test(checkThisHashValue)) {
                    // DEBUG INFO
                    //console.log('this is alpha numeric, setting user to win');
                
                    // Building a win query
                    userBet = {
                        user: currentUser,
                        betBlock: bettingBlock,
                        betAmount: userFinalInput,
                        betResult: 'win'
                    };
                    
                    // If they won, add the values back for them
                    // Calculate what we need to update the user's game points
                    // Need to x2 userFinalInput because we have already taken it out from userGamePoints
                    //const tempGamePointUpdate = parseFloat(userGamePoints) + parseFloat(userCommEarned) + (parseFloat(userFinalInput) * 2);
                    const tempGamePointUpdate = parseFloat(userGamePoints) + (parseFloat(userFinalInput) * 2);
                    updateUserGamePoints(tempGamePointUpdate, 'WinBetMode').then(() => {
                        refreshUserStats();
                    });

                    setLoadingGif('win');
                    setLightBoxDisplay(true);
                }
                else {
                    // DEBUG INFO
                    //console.log('this is NOT alpha numeric, setting user to lose');
                
                    // Building a lose query
                    userBet = {
                        user: currentUser,
                        betBlock: bettingBlock,
                        betAmount: userFinalInput,
                        betResult: 'lose'
                    };
                
                    // If they lost, just provide the deducted value, update it, and refresh the user data
                    //console.log('if they lost update with this gamePoint: ' + userFinalInput);
                    updateUserGamePoints(userFinalInput, 'LoseBetMode').then(() => {
                        //console.log('after updateUserGamePoints, firing off refreshUserStats...');
                        refreshUserStats();
                    });

                    setLoadingGif('lose');
                    setLightBoxDisplay(true);
                }
            }

            if (userBetMode === 'Mode1A') {
                if (regex4FirstDigit.test(checkThisHashValue) && regex4LastChar.test(checkThisHashValue)) {
                    // DEBUG INFO
                    //console.log('this is alpha numeric, setting user to win');
                
                    // Building a win query
                    userBet = {
                        user: currentUser,
                        betBlock: bettingBlock,
                        betAmount: userFinalInput,
                        betResult: 'win'
                    };
                    
                    // If they won, add the values back for them
                    // Calculate what we need to update the user's game points
                    // Need to x2 userFinalInput because we have already taken it out from userGamePoints
                    //const tempGamePointUpdate = parseFloat(userGamePoints) + parseFloat(userCommEarned) + (parseFloat(userFinalInput) * 2);
                    const tempGamePointUpdate = parseFloat(userGamePoints) + (parseFloat(userFinalInput) * 2);
                    updateUserGamePoints(tempGamePointUpdate, 'WinBetMode').then(() => {
                        refreshUserStats();
                    });

                    setLoadingGif('win');
                    setLightBoxDisplay(true);
                }
                else {
                    // DEBUG INFO
                    //console.log('this is NOT alpha numeric, setting user to lose');
                
                    // Building a lose query
                    userBet = {
                        user: currentUser,
                        betBlock: bettingBlock,
                        betAmount: userFinalInput,
                        betResult: 'lose'
                    };
                
                    // If they lost, just provide the deducted value, update it, and refresh the user data
                    //console.log('if they lost update with this gamePoint: ' + userFinalInput);
                    updateUserGamePoints(userFinalInput, 'LoseBetMode').then(() => {
                        //console.log('after updateUserGamePoints, firing off refreshUserStats...');
                        refreshUserStats();
                    });

                    setLoadingGif('lose');
                    setLightBoxDisplay(true);
                }
            }

            if (userBetMode === 'ModeA1') {
                if (regex4FirstChar.test(checkThisHashValue) && regex4LastDigit.test(checkThisHashValue)) {
                    // DEBUG INFO
                    //console.log('this is alpha numeric, setting user to win');
                
                    // Building a win query
                    userBet = {
                        user: currentUser,
                        betBlock: bettingBlock,
                        betAmount: userFinalInput,
                        betResult: 'win'
                    };
                    
                    // If they won, add the values back for them
                    // Calculate what we need to update the user's game points
                    // Need to x2 userFinalInput because we have already taken it out from userGamePoints
                    //const tempGamePointUpdate = parseFloat(userGamePoints) + parseFloat(userCommEarned) + (parseFloat(userFinalInput) * 2);
                    const tempGamePointUpdate = parseFloat(userGamePoints) + (parseFloat(userFinalInput) * 2);
                    updateUserGamePoints(tempGamePointUpdate, 'WinBetMode').then(() => {
                        refreshUserStats();
                    });

                    setLoadingGif('win');
                    setLightBoxDisplay(true);
                }
                else {
                    // DEBUG INFO
                    //console.log('this is NOT alpha numeric, setting user to lose');
                
                    // Building a lose query
                    userBet = {
                        user: currentUser,
                        betBlock: bettingBlock,
                        betAmount: userFinalInput,
                        betResult: 'lose'
                    };
                
                    // If they lost, just provide the deducted value, update it, and refresh the user data
                    //console.log('if they lost update with this gamePoint: ' + userFinalInput);
                    updateUserGamePoints(userFinalInput, 'LoseBetMode').then(() => {
                        //console.log('after updateUserGamePoints, firing off refreshUserStats...');
                        refreshUserStats();
                    });

                    setLoadingGif('lose');
                    setLightBoxDisplay(true);
                }
            }
            
            // Update the user's total played amount
            updateUserTotalPlayed(userFinalInput);

            // Submit the query
            await API.graphql({
                query: mutations.createUserBets,
                variables: {input: userBet},
                authMode:'AMAZON_COGNITO_USER_POOLS'
            });
        }

        // Clear the values so its back to normal and ready for the next round
        setUserFinalInput('');
        setBetConfirmationMessage('');
        setUserBetMode('');
        setGamePlayButtonContLeftUp('game-play-button-cont-left');
        setGamePlayButtonContLeftDown('game-play-button-cont-left');
        setGamePlayButtonContRightUp('game-play-button-cont-right');
        setGamePlayButtonContRightDown('game-play-button-cont-right');
        await delay(2700);
        setLightBoxDisplay(false);
        setLoadingGif('');
    }

    async function refreshUserStats() {
        Auth.currentAuthenticatedUser({
            // Temporarily setting this to false, this needs to be true during production
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          }).then(user => {
              // DEBUG INFO
              //console.log('refreshing user attributes...');
              //setCurrentUser(user.attributes.email.split("@", 1));
              setUserGamePoints(user.attributes.zoneinfo);
              setUserCommEarned(user.attributes.name);
              setUserPlayStatus(user.attributes.address);
              
              setUserGamePlan(user.attributes.gender);
              setGameExpectedValue(user.attributes.locale);
              
              setCurrentUser(user.attributes.email);
              setCurrentUser((state) => {
                  setCurrentUser(state);
              
                  fetchBetHistory(state).then((x) => {
                      setBetHistory(x);
                  });
            })
            
            // Checking whether the user has won today
            // Because we need to enable them to continue playing if they HAVE won
            // But without the protection of insurance, which is why this page is created
            if (user.attributes.address === 'disallow') {
                setCanContPlay(true);
            }
        })
        .catch(err => {});
    }

    async function updateUserTotalPlayed(userPlayedThisAmount) {
        //DEBUG INFO
        //console.log('inside updateUserTotalPlayed function');
        const user = await Auth.currentAuthenticatedUser();
        const tempNewTotalPlayed = parseFloat(user.attributes.website) + parseFloat(userPlayedThisAmount);
        await Auth.updateUserAttributes(user, {
          'website': tempNewTotalPlayed.toString()
        });
    }

    async function updateUserGamePoints(updateWithThisValue, mode) {
        //DEBUG INFO
        //console.log('inside updateUserGamePoints function with: ' + updateWithThisValue);
        //console.log('updateWithThisValue is typeof: ' + typeof updateWithThisValue);
        const user = await Auth.currentAuthenticatedUser();
        //console.log('updateUserGamePoints is mode: ' + mode + ' with value: ' + updateWithThisValue);
        if (mode === 'WinBetMode') {
            //console.log('Inside InsuranceClaim, Winbet and TopUpMode mode');
            await Auth.updateUserAttributes(user, {
                'zoneinfo': updateWithThisValue.toString()
            });
            return;
        }

        if (mode === 'LoseBetMode') { 
            //console.log('Inside LoseBetMode...');
            // If they lost less than what their commission earned has
            // It means they have enough in their commission earned amount
            //console.log('right before comparison, updateWithThisValue is: ' + updateWithThisValue + ' userCommEarned is: ' + userCommEarned);
            if (parseFloat(updateWithThisValue) <= parseFloat(userCommEarned)) {
                // Simply deduct the value and update
                const tempSubtract = parseFloat(user.attributes.name) - parseFloat(updateWithThisValue);
                //console.log('they have enough comm, tempSubtract is now: ' + tempSubtract);
                await Auth.updateUserAttributes(user, {
                    'name': tempSubtract.toString()
                });
                //return;
            }
        
            // If they lost more than what their commission earned has
            // We need to zero out their commission earned
            // and then subtract the rest from their total game points
            if (parseFloat(updateWithThisValue) > parseFloat(user.attributes.name)) {
                // Zero out their commission earned field
                //console.log('they dont have enough commission, Going to zero out their commission earned...');
                await Auth.updateUserAttributes(user, {
                    'name': '0'
                });

                // Do the calculations for remaining amount
                const remainingSubtract = parseFloat(updateWithThisValue) - parseFloat(user.attributes.name);
                const gamePointFinalValue = parseFloat(user.attributes.zoneinfo) - parseFloat(remainingSubtract);
                //console.log('updating the remaining with: ' + gamePointFinalValue);

                // Then fire it off to update
                await Auth.updateUserAttributes(user, {
                    'zoneinfo': gamePointFinalValue.toString()
                });
            }
        }
    }

    async function fetchBetHistory(fetchThisUser) {
        // DEBUG INFO
        //console.log(fetchThisUser);
        
        //const betHistoryRaw = await API.graphql({ query: queries.listUserBets, variables: { filter: {user: { eq: fetchThisUser}, isFirstTopUp: { eq: 'false'} }}});
        //const betHistoryArray = betHistoryRaw.data.listUserBets.items;
        
        const betHistoryRaw = await API.graphql({
            query: queries.betsByDate,
            variables: {
              sortDirection: 'DESC',
              user: fetchThisUser,
              limit: 10
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        
        const betHistoryArray = betHistoryRaw.data.betsByDate.items;
        return betHistoryArray
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

      if (canContPlay) {
        return (
            <>
            <div className='game-container-back'>
                <div className='gamehero-container-back'>
                    <a href='/home'>
                        <button className='close-button'>
                            <AiOutlineArrowLeft />Back
                        </button>
                    </a>
                </div>
            </div>
        
            <div className='game-container'>
                <div className='gamehero-container'>
                    <div className='gamehero-containercol'>
                        <div className='gamehero-containerrow'>
                            <img src={TronLogo} className='gamelogo-small' />
                            <h4 className='gametype-title'>TRX - TRON</h4>
                            <div className='gamepage-gamepoint'>GP: {parseFloat(parseFloat(userGamePoints) + parseFloat(userCommEarned)).toFixed(2)}</div>
                        </div>
                        
                        <span>Previous Block</span>
                        <h3>{latestBlock}</h3>
                        <span className='hashResults'>{latestHash.substring(0, 62)} <b className='hashLastTwo'>{latestHash.substring(62)}</b></span>
                        
                    </div>
                </div>
            </div>
        
            <div className='game-container'>
                <div className='gamehero-container'>
                    <div className='gamehero-containerrow'>
                        <div className='gamehero-floatleft'>
                            <span>Round ends in</span>
                            <br />
                            <span>{roundEndTime} seconds</span>
                        </div>
                        
                        <div className='gamehero-floatright'>
                            <span>Next round in</span>
                            <br />
                            <span>{nextRoundTime} seconds</span>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className='game-container'>
                <div className='gamehero-container'>
                    <div className='gamehero-containercol'>
                        <h3>{betMessage}</h3>
                        <h4>{bettingBlock}</h4>
                        
                        <div className='gamehero-containerrow-cont'>
                            <div className='gamehero-floatleft-cont'>
                                <input
                                    type='text'
                                    className='game-play-input'
                                    id='betInputValue'
                                    value={betInputValue}
                                    disabled={betInputDisabled}
                                    onChange={processBetInputChangeValue}
                                    placeholder='Amount'
                                />
                            </div>
                        </div>

                        <div className='gamehero-containerrow-cont-button'>
                            <div className='gamehero-floatleft'>

                            <button className={gamePlayButtonContLeftUp}
                                    disabled={betInputDisabled}
                                    onClick={() => {
                                        SubmitBet('Mode11');
                                        setUserBetMode('Mode11');
                                        }}>
                                            PLAY 1-1
                                </button>
                            </div>
                            
                            <div className='gamehero-floatright'>
                                <button className={gamePlayButtonContRightUp}
                                    disabled={betInputDisabled}
                                    onClick={() => {
                                        SubmitBet('ModeAA');
                                        setUserBetMode('ModeAA');
                                    }}>PLAY A-A
                                </button>
                            </div>
                        </div>

                        <div className='gamehero-containerrow-cont-button'>
                            <div className='gamehero-floatleft'>

                            <button className={gamePlayButtonContLeftDown}
                                    disabled={betInputDisabled}
                                    onClick={() => {
                                        SubmitBet('ModeA1');
                                        setUserBetMode('ModeA1');
                                        }}>
                                            PLAY A-1
                                </button>
                            </div>
                            
                            <div className='gamehero-floatright'>
                                <button className={gamePlayButtonContRightDown}
                                    disabled={betInputDisabled}
                                    onClick={() => {
                                        SubmitBet('Mode1A');
                                        setUserBetMode('Mode1A');
                                    }}>
                                        PLAY 1-A
                                </button>
                            </div>
                        </div>

                        <p>{allowBetMessage}</p>
                        <p>{betConfirmationMessage}</p>
                    </div>
                </div>
            </div>
        
            <div className='game-container'>
                <div className='gamehero-container'>
                    <h4 className='gamehero-container-your-history'>Your history</h4>
                    <hr />
                    <table id='bethistory-table'>
                        <tbody>
                            <tr>
                                <th>Time</th>
                                <th>Block</th>
                                <th>Amount</th>
                                <th>Result</th>
                                </tr>
                                {
                                    betHistory.map((bet, index) => (
                                        <tr key={index}>
                                            <td>{bet.createdAt}</td>
                                            <td>{bet.betBlock}</td>
                                            <td>$ {bet.betAmount}</td>
                                            <td>{bet.betResult}</td>
                                        </tr>
                                    ))
                                }
                        </tbody>
                    </table>
                </div>
            </div>
            <CoinRainBox trigger={lightboxDisplay} setTrigger={setLightBoxDisplay} gifLoad={loadingGif} />
            </>
          );
      }

      else {
        return (
            <>
            </>
        );
    }
  
}

export default GameTronCont;