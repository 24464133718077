import React from 'react';
import '../../css/home.css';
import Navbar from '../layouts/navbar';
import GameHero from '../layouts/gamehero';

function Game() {
  return (
    <>
    <div className='game-container'>
        <div className='navbar-container'>
            <Navbar />
        </div>
    </div>
    
    <div className='game-container'>
      <GameHero />
    </div>
    
    </>
  );
}

export default Game;