import React from 'react';
import '../../css/navbar.css';
import { AiOutlineHome } from 'react-icons/ai';
import { RiCoinsLine } from 'react-icons/ri';
import { CgProfile } from 'react-icons/cg';

function NavBar() {
  return (
    <>
    <div className="navbar-container">
        <div className='icon-container'>
            <a href='/home' className='icon-container'>
                <AiOutlineHome className='navbar-icon' size={26} />
                <span>Home</span>
            </a>
        </div>
        <div className='icon-container'>
            <a href='/assets' className='icon-container'>
                <RiCoinsLine className='navbar-icon' size={26} />
                <span>Assets</span>
            </a>
        </div>
        <div className='icon-container'>
            <a href='/profile' className='icon-container'>
                <CgProfile className='navbar-icon' size={26} />
                <span>Profile</span>
            </a>
        </div>
    </div>
    </>
  );
}

export default NavBar;