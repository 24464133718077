import React, { useEffect } from 'react';
import '../../css/assets.css';
import '@aws-amplify/ui-react/styles.css';
import AssetsHero from '../layouts/assetshero';
import Navbar from '../layouts/navbar';
import { GiTwoCoins } from 'react-icons/gi';

import { Authenticator } from '@aws-amplify/ui-react';

function Assets() {
  return (
    <>
    <div className='assets-container'>
        <div className='navbar-container'>
            <Navbar />
        </div>
    </div>
    
    <div className='assets-container'>
        <div className='navbar-container-inner'>
        <GiTwoCoins className='navbar-icon-assets' size={26} /> ASSETS
        </div>
    </div>

    <Authenticator>
        <AssetsHero />
    </Authenticator>
    </>
  );
}

export default Assets;