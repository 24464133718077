import React, { useState, useEffect } from 'react';
import '../../css/profile.css';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import { Auth } from 'aws-amplify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PasswordChange(props) {

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Some static display messages
  const changeSuccessMessage = 'Password change successful';
  const passwordsDoNotMatchMessage = 'New password and confirmation password do not match.';
  const unknownErrorMessage = 'Unknown error occureed. Check the information you have provided, or contact customer support.';

  const successToast = () => {
    toast.success(changeSuccessMessage, {
      position: toast.POSITION.TOP_CENTER
    });
  }
  
  const warningToast = () => {
    toast.error(passwordsDoNotMatchMessage, {
      position: toast.POSITION.TOP_CENTER
    });
  }

  const errorToast = () => {
    toast.error(unknownErrorMessage, {
      position: toast.POSITION.TOP_CENTER
    });
  }

  const genericErrorToast = (errorMessage) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER
    });
  }

  function PasswordChange() {
    Auth.currentAuthenticatedUser()
    .then(user => {
      // Do some input checking first
      if (newPassword === confirmPassword) {
        // DEBUG INFO
        //console.log('firing the POST request');

        // This is the POST to change password
        return Auth.changePassword(user, oldPassword, newPassword);
      }

      else if (newPassword != confirmPassword) {
        // DEBUG INFO
        //console.log('newPassword is not = to confirmPassword');

        warningToast();
      }

      else {
        // DEBUG INFO
        //console.log('anything else');

        errorToast();
        
      }
    })
    //.then(data => console.log('this is the data: ' + data))
    .then(data => successToast())
    .catch(err => {
      const errString = JSON.stringify(err);
      // DEBUG INFO
      console.log('DEBUG INFO: ' + err);
      console.log('DEBUG INFO: ' + errString);

      if (errString.includes('LimitExceededException')) {
        genericErrorToast('Attempt limit exceeded, try again after some time.');
      }

      else if (errString.includes('NotAuthorizedException')) {
        genericErrorToast('Incorrect password entered.');
      }

      else if (errString.includes('InvalidParameterException')) {
        genericErrorToast('New password must have a minimum of 8 characters in length.');
      }

      else {
        genericErrorToast('Unknown error. Contact customer support for assistance.');
      }
    });
    //.catch(err => wrongPasswordToast());

    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }

  function HandleUserInput(e) {
    if (e.target.id === 'oldpassword') {
      setOldPassword(e.target.value);
    }

    else if (e.target.id === 'newpassword') {
      setNewPassword(e.target.value);
    }

    else if (e.target.id === 'newpassword2') {
      setConfirmPassword(e.target.value);
    }
  }


  return (
    <>
    <ToastContainer
    newestOnTop
    closeOnClick
    pauseOnFocusLoss
    pauseOnHover
    className='toastMessage-withdraw'
    />

<div className='game-container-inner'>
        <div className='gamehero-container-back'>
            <a href='/profile'>
                <button className='close-button'>
                    <AiOutlineArrowLeft />Back
                </button>
            </a>
        </div>
    </div>

    <div className='assets-container'>
        <div className='navbar-container-inner'>
        <span className='deposit-text'>CHANGE PASSWORD</span>
        </div>
    </div>

    <div className='profile-container'>
        <div className='profile-container-col'>
            
            <div className='passwordchange-container'>
                <span><span className='redinput'>*</span>Current Password</span>

                <input
                  id='oldpassword'
                  type='password'
                  value={oldPassword}
                  onChange={HandleUserInput}
                  className='passwordchange-input'
                />

                <span><span className='redinput'>*</span>New Password</span>
                <input
                  id='newpassword'
                  type='password'
                  value={newPassword}
                  onChange={HandleUserInput}
                  className='passwordchange-input'
                />

                <span><span className='redinput'>*</span>New Password Confirmation</span>
                <input
                  id='newpassword2'
                  type='password'
                  value={confirmPassword}
                  onChange={HandleUserInput}
                  className='passwordchange-input'
                />

                <button className='passwordchange-submitbutton' onClick={PasswordChange}>
                  <RiLockPasswordLine /> Submit
                </button>
            </div>
        </div>
    </div>

    </>
  )
}

export default PasswordChange;