/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWithdraws = /* GraphQL */ `
  mutation CreateWithdraws(
    $input: CreateWithdrawsInput!
    $condition: ModelWithdrawsConditionInput
  ) {
    createWithdraws(input: $input, condition: $condition) {
      id
      type
      createdAt
      withdrawAmount
      walletAddress
      status
      requestedBy
      updatedAt
      owner
    }
  }
`;
export const updateWithdraws = /* GraphQL */ `
  mutation UpdateWithdraws(
    $input: UpdateWithdrawsInput!
    $condition: ModelWithdrawsConditionInput
  ) {
    updateWithdraws(input: $input, condition: $condition) {
      id
      type
      createdAt
      withdrawAmount
      walletAddress
      status
      requestedBy
      updatedAt
      owner
    }
  }
`;
export const deleteWithdraws = /* GraphQL */ `
  mutation DeleteWithdraws(
    $input: DeleteWithdrawsInput!
    $condition: ModelWithdrawsConditionInput
  ) {
    deleteWithdraws(input: $input, condition: $condition) {
      id
      type
      createdAt
      withdrawAmount
      walletAddress
      status
      requestedBy
      updatedAt
      owner
    }
  }
`;
export const createDeposits = /* GraphQL */ `
  mutation CreateDeposits(
    $input: CreateDepositsInput!
    $condition: ModelDepositsConditionInput
  ) {
    createDeposits(input: $input, condition: $condition) {
      id
      type
      createdAt
      depositAmount
      status
      requestedBy
      updatedAt
      owner
    }
  }
`;
export const updateDeposits = /* GraphQL */ `
  mutation UpdateDeposits(
    $input: UpdateDepositsInput!
    $condition: ModelDepositsConditionInput
  ) {
    updateDeposits(input: $input, condition: $condition) {
      id
      type
      createdAt
      depositAmount
      status
      requestedBy
      updatedAt
      owner
    }
  }
`;
export const deleteDeposits = /* GraphQL */ `
  mutation DeleteDeposits(
    $input: DeleteDepositsInput!
    $condition: ModelDepositsConditionInput
  ) {
    deleteDeposits(input: $input, condition: $condition) {
      id
      type
      createdAt
      depositAmount
      status
      requestedBy
      updatedAt
      owner
    }
  }
`;
export const createClientUsers = /* GraphQL */ `
  mutation CreateClientUsers(
    $input: CreateClientUsersInput!
    $condition: ModelClientUsersConditionInput
  ) {
    createClientUsers(input: $input, condition: $condition) {
      id
      upline
      isFirstTopUp
      referredUserGamePlan
      createdAt
      updatedAt
    }
  }
`;
export const updateClientUsers = /* GraphQL */ `
  mutation UpdateClientUsers(
    $input: UpdateClientUsersInput!
    $condition: ModelClientUsersConditionInput
  ) {
    updateClientUsers(input: $input, condition: $condition) {
      id
      upline
      isFirstTopUp
      referredUserGamePlan
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientUsers = /* GraphQL */ `
  mutation DeleteClientUsers(
    $input: DeleteClientUsersInput!
    $condition: ModelClientUsersConditionInput
  ) {
    deleteClientUsers(input: $input, condition: $condition) {
      id
      upline
      isFirstTopUp
      referredUserGamePlan
      createdAt
      updatedAt
    }
  }
`;
export const createUserBets = /* GraphQL */ `
  mutation CreateUserBets(
    $input: CreateUserBetsInput!
    $condition: ModelUserBetsConditionInput
  ) {
    createUserBets(input: $input, condition: $condition) {
      user
      betBlock
      betResult
      betAmount
      createdAt
      id
      updatedAt
      owner
    }
  }
`;
export const updateUserBets = /* GraphQL */ `
  mutation UpdateUserBets(
    $input: UpdateUserBetsInput!
    $condition: ModelUserBetsConditionInput
  ) {
    updateUserBets(input: $input, condition: $condition) {
      user
      betBlock
      betResult
      betAmount
      createdAt
      id
      updatedAt
      owner
    }
  }
`;
export const deleteUserBets = /* GraphQL */ `
  mutation DeleteUserBets(
    $input: DeleteUserBetsInput!
    $condition: ModelUserBetsConditionInput
  ) {
    deleteUserBets(input: $input, condition: $condition) {
      user
      betBlock
      betResult
      betAmount
      createdAt
      id
      updatedAt
      owner
    }
  }
`;
